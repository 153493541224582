<div class="modal-biomarcador">
    <div class="modal-backdrop"></div>

    <header class="bg-light-blue modal-biomarcador-header d-flex">
        <div style="flex: 1;" class="d-flex justify-content-center align-items-center">
            <div>
                <div>
                    <span class="modal-biomarcador-header-safe">Biomarcadores SAFE®</span>
                </div>
                <div>
                    <span class="text-yellow modal-biomarcador-header-tipo-biomarcador">
                        Chegamos ao meio, vamos em frente.
                    </span>
                </div>
            </div>
            <div style="padding-left: 20px;">
                <img src="assets/svg/biomarcador-1.svg" alt="">
            </div>
        </div>

        <div style="flex: 1;" class="d-flex justify-content-end align-items-center ">
            <div class="identificador-pagina">
                <span class="text-yellow">5/</span>
                <span class="text-light">10</span>
            </div>
        </div>


        <div class="fechar-modal" (click)="handleClickFecharModal()">
            <img src="assets/svg/close-icon.svg" alt="">
        </div>
    </header>
    <div class="modal-bio-form content bg-light p-4">
        <h1 class="form-title">Preencha os Dados</h1>
        <div class="row vertical-content">
            <form class="form-form p-3" [formGroup]="formGroupEtapa5">
                <div class="col-md-12 row">
                    <div class="col-12">
                        <span> Usando um escala de 1 a 7 , indique o quanto você concorda com cada item, marcando a opção mais apropriada na linha que depende de cada afirmação. </span>
                    </div>
                    <div class="col-md-6 sm-12 mt-3">
                        <span
                            class="form-label-texts"> Na maioria dos aspectos minha vida está próxima do meu ideal. </span>
                        <div class="mt-1 d-flex align-items-center">
                            <input
                                type="checkbox"
                                class="form-input-checkbox"
                                [checked]="formGroupEtapa5.get('ProxIdeal').value == 1"
                                (click)="toggleCheckboxWidthValue('ProxIdeal', 1)"
                                [disabled]="desativarCampos"

                            >
                            <label class="form-label-texts-helper-check">1 - Discordo plenamente</label>
                        </div>
                        <div class="mt-1 d-flex align-items-center">
                            <input
                                type="checkbox"
                                class="form-input-checkbox"
                                [checked]="formGroupEtapa5.get('ProxIdeal').value == 2"
                                (click)="toggleCheckboxWidthValue('ProxIdeal', 2)"
                                [disabled]="desativarCampos"
                            >
                            <label class="form-label-texts-helper-check">2 - Discordo</label>
                        </div>
                        <div class="mt-1 d-flex align-items-center">
                            <input
                                type="checkbox"
                                class="form-input-checkbox"
                                [checked]="formGroupEtapa5.get('ProxIdeal').value == 3"
                                (click)="toggleCheckboxWidthValue('ProxIdeal', 3)"
                                [disabled]="desativarCampos"
                            >
                            <label class="form-label-texts-helper-check">3 - Discordo um pouco</label>
                        </div>
                        <div class="mt-1 d-flex align-items-center">
                            <input
                                type="checkbox"
                                class="form-input-checkbox"
                                [checked]="formGroupEtapa5.get('ProxIdeal').value == 4"
                                (click)="toggleCheckboxWidthValue('ProxIdeal', 4)"
                                [disabled]="desativarCampos"
                            >
                            <label class="form-label-texts-helper-check">4 - Não concordo nem
                                discordo</label>
                        </div>
                        <div class="mt-1 d-flex align-items-center">
                            <input
                                type="checkbox"
                                class="form-input-checkbox"
                                [checked]="formGroupEtapa5.get('ProxIdeal').value == 5"
                                (click)="toggleCheckboxWidthValue('ProxIdeal', 5)"
                                [disabled]="desativarCampos"
                            >
                            <label class="form-label-texts-helper-check">5 - Concordo um pouco</label>
                        </div>
                        <div class="mt-1 d-flex align-items-center">
                            <input
                                type="checkbox"
                                class="form-input-checkbox"
                                [checked]="formGroupEtapa5.get('ProxIdeal').value == 6"
                                (click)="toggleCheckboxWidthValue('ProxIdeal', 6)"
                                [disabled]="desativarCampos"
                            >
                            <label class="form-label-texts-helper-check">6 - Concordo</label>
                        </div>
                        <div class="mt-1 d-flex align-items-center">
                            <input
                                type="checkbox"
                                class="form-input-checkbox"
                                [checked]="formGroupEtapa5.get('ProxIdeal').value == 7"
                                (click)="toggleCheckboxWidthValue('ProxIdeal', 7)"
                                [disabled]="desativarCampos"
                            >
                            <label class="form-label-texts-helper-check">7 - Concordo plenamente</label>
                        </div>
                    </div>
                    <div class="col-md-6 sm-12 mt-3">
                        <span class="form-label-texts">As condições da minha vida são excelentes.</span>
                        <div class="mt-1 d-flex align-items-center">
                            <input
                                [checked]="formGroupEtapa5.get('VidaExcelente').value == 1"
                                (click)="toggleCheckboxWidthValue('VidaExcelente', 1)"
                                type="checkbox"
                                class="form-input-checkbox"
                                [disabled]="desativarCampos"
                            >
                            <label class="form-label-texts-helper-check">1 - Discordo
                                plenamente</label>
                        </div>
                        <div class="mt-1 d-flex align-items-center">
                            <input
                                [checked]="formGroupEtapa5.get('VidaExcelente').value == 2"
                                (click)="toggleCheckboxWidthValue('VidaExcelente', 2)"
                                type="checkbox"
                                class="form-input-checkbox"
                                [disabled]="desativarCampos"
                            >
                            <label class="form-label-texts-helper-check">2 - Discordo</label>
                        </div>
                        <div class="mt-1 d-flex align-items-center">
                            <input
                                [checked]="formGroupEtapa5.get('VidaExcelente').value == 3"
                                (click)="toggleCheckboxWidthValue('VidaExcelente', 3)"
                                type="checkbox"
                                class="form-input-checkbox"
                                [disabled]="desativarCampos"
                            >
                            <label class="form-label-texts-helper-check">3 - Discordo um
                                pouco</label>
                        </div>
                        <div class="mt-1 d-flex align-items-center">
                            <input
                                [checked]="formGroupEtapa5.get('VidaExcelente').value == 4"
                                (click)="toggleCheckboxWidthValue('VidaExcelente', 4)"
                                type="checkbox"
                                class="form-input-checkbox"
                                [disabled]="desativarCampos"
                            >
                            <label class="form-label-texts-helper-check">4 - Não concordo nem
                                discordo</label>
                        </div>
                        <div class="mt-1 d-flex align-items-center">
                            <input
                                [checked]="formGroupEtapa5.get('VidaExcelente').value == 5"
                                (click)="toggleCheckboxWidthValue('VidaExcelente', 5)"
                                type="checkbox"
                                class="form-input-checkbox"
                                [disabled]="desativarCampos"
                            >
                            <label class="form-label-texts-helper-check">5 - Concordo um
                                pouco</label>
                        </div>
                        <div class="mt-1 d-flex align-items-center">
                            <input
                                [checked]="formGroupEtapa5.get('VidaExcelente').value == 6"
                                (click)="toggleCheckboxWidthValue('VidaExcelente', 6)"
                                type="checkbox"
                                class="form-input-checkbox"
                                [disabled]="desativarCampos"
                            >
                            <label class="form-label-texts-helper-check">6 - Concordo</label>
                        </div>
                        <div class="mt-1 d-flex align-items-center">
                            <input
                                [checked]="formGroupEtapa5.get('VidaExcelente').value == 7"
                                (click)="toggleCheckboxWidthValue('VidaExcelente', 7)"
                                type="checkbox"
                                class="form-input-checkbox"
                                [disabled]="desativarCampos"
                            >
                            <label class="form-label-texts-helper-check">7 - Concordo
                                plenamente</label>
                        </div>
                    </div>
                    <div class="col-md-6 sm-12 mt-3">
                        <span class="form-label-texts"> Estou inteiramente satisfeito com minha vida. </span>
                        <div class="mt-1 d-flex align-items-center">
                            <input
                                [checked]="formGroupEtapa5.get('SatisfeitoVida').value == 1"
                                (click)="toggleCheckboxWidthValue('SatisfeitoVida', 1)"
                                type="checkbox"
                                class="form-input-checkbox"
                                [disabled]="desativarCampos"
                            >
                            <label class="form-label-texts-helper-check">1 - Discordo
                                plenamente</label>
                        </div>
                        <div class="mt-1 d-flex align-items-center">
                            <input
                                [checked]="formGroupEtapa5.get('SatisfeitoVida').value == 2"
                                (click)="toggleCheckboxWidthValue('SatisfeitoVida', 2)"
                                type="checkbox"
                                class="form-input-checkbox"
                                [disabled]="desativarCampos"
                            >
                            <label class="form-label-texts-helper-check">2 - Discordo</label>
                        </div>
                        <div class="mt-1 d-flex align-items-center">
                            <input
                                [checked]="formGroupEtapa5.get('SatisfeitoVida').value == 3"
                                (click)="toggleCheckboxWidthValue('SatisfeitoVida', 3)"
                                type="checkbox"
                                class="form-input-checkbox"
                                [disabled]="desativarCampos"
                            >
                            <label class="form-label-texts-helper-check">3 - Discordo um
                                pouco</label>
                        </div>
                        <div class="mt-1 d-flex align-items-center">
                            <input
                                [checked]="formGroupEtapa5.get('SatisfeitoVida').value == 4"
                                (click)="toggleCheckboxWidthValue('SatisfeitoVida', 4)"
                                type="checkbox"
                                class="form-input-checkbox"
                                [disabled]="desativarCampos"
                            >
                            <label class="form-label-texts-helper-check">4 - Não concordo nem
                                discordo</label>
                        </div>
                        <div class="mt-1 d-flex align-items-center">
                            <input
                                [checked]="formGroupEtapa5.get('SatisfeitoVida').value == 5"
                                (click)="toggleCheckboxWidthValue('SatisfeitoVida', 5)"
                                type="checkbox"
                                class="form-input-checkbox"
                                [disabled]="desativarCampos"
                            >
                            <label class="form-label-texts-helper-check">5 - Concordo um
                                pouco</label>
                        </div>
                        <div class="mt-1 d-flex align-items-center">
                            <input
                                [checked]="formGroupEtapa5.get('SatisfeitoVida').value == 6"
                                (click)="toggleCheckboxWidthValue('SatisfeitoVida', 6)"
                                type="checkbox"
                                class="form-input-checkbox"
                                [disabled]="desativarCampos"
                            >
                            <label class="form-label-texts-helper-check">6 - Concordo</label>
                        </div>
                        <div class="mt-1 d-flex align-items-center">
                            <input
                                [checked]="formGroupEtapa5.get('SatisfeitoVida').value == 7"
                                (click)="toggleCheckboxWidthValue('SatisfeitoVida', 7)"
                                type="checkbox"
                                class="form-input-checkbox"
                                [disabled]="desativarCampos"
                            >
                            <label class="form-label-texts-helper-check">7 - Concordo
                                plenamente</label>
                        </div>
                    </div>
                    <div class="col-md-6 sm-12 mt-3">
                        <span class="form-label-texts"> Até hoje, consegui as coisas mais importantes que desejei na vida. </span>
                        <div class="mt-1 d-flex align-items-center">
                            <input
                                [checked]="formGroupEtapa5.get('DesejeiVida').value == 1"
                                (click)="toggleCheckboxWidthValue('DesejeiVida', 1)"
                                type="checkbox"
                                class="form-input-checkbox"
                                [disabled]="desativarCampos"
                            >
                            <label class="form-label-texts-helper-check">1 - Discordo
                                plenamente</label>
                        </div>
                        <div class="mt-1 d-flex align-items-center">
                            <input
                                [checked]="formGroupEtapa5.get('DesejeiVida').value == 2"
                                (click)="toggleCheckboxWidthValue('DesejeiVida', 2)"
                                type="checkbox"
                                class="form-input-checkbox"
                                [disabled]="desativarCampos"
                            >
                            <label class="form-label-texts-helper-check">2 - Discordo</label>
                        </div>
                        <div class="mt-1 d-flex align-items-center">
                            <input
                                [checked]="formGroupEtapa5.get('DesejeiVida').value == 3"
                                (click)="toggleCheckboxWidthValue('DesejeiVida', 3)"
                                type="checkbox"
                                class="form-input-checkbox"
                                [disabled]="desativarCampos"
                            >
                            <label class="form-label-texts-helper-check">3 - Discordo um pouco</label>
                        </div>
                        <div class="mt-1 d-flex align-items-center">
                            <input
                                [checked]="formGroupEtapa5.get('DesejeiVida').value == 4"
                                (click)="toggleCheckboxWidthValue('DesejeiVida', 4)"
                                type="checkbox"
                                class="form-input-checkbox"
                                [disabled]="desativarCampos"
                            >
                            <label class="form-label-texts-helper-check">4 - Não concordo nem
                                discordo</label>
                        </div>
                        <div class="mt-1 d-flex align-items-center">
                            <input
                                [checked]="formGroupEtapa5.get('DesejeiVida').value == 5"
                                (click)="toggleCheckboxWidthValue('DesejeiVida', 5)"
                                type="checkbox"
                                class="form-input-checkbox"
                                [disabled]="desativarCampos"
                            >
                            <label class="form-label-texts-helper-check">5 - Concordo um pouco</label>
                        </div>
                        <div class="mt-1 d-flex align-items-center">
                            <input
                                [checked]="formGroupEtapa5.get('DesejeiVida').value == 6"
                                (click)="toggleCheckboxWidthValue('DesejeiVida', 6)"
                                type="checkbox"
                                class="form-input-checkbox"
                                [disabled]="desativarCampos"
                            >
                            <label class="form-label-texts-helper-check">6 - Concordo</label>
                        </div>
                        <div class="mt-1 d-flex align-items-center">
                            <input
                                [checked]="formGroupEtapa5.get('DesejeiVida').value == 7"
                                (click)="toggleCheckboxWidthValue('DesejeiVida', 7)"
                                type="checkbox"
                                class="form-input-checkbox"
                                [disabled]="desativarCampos"

                            >
                            <label class="form-label-texts-helper-check">7 - Concordo
                                plenamente</label>
                        </div>
                    </div>
                    <div class="col-md-6 sm-12 mt-3">
                        <span class="form-label-texts"> Se pudesse viver outra vez, não mudaria nada. </span>
                        <div class="mt-1 d-flex align-items-center">
                            <input
                                [checked]="formGroupEtapa5.get('MudariaNada').value == 1"
                                (click)="toggleCheckboxWidthValue('MudariaNada', 1)"
                                type="checkbox"
                                class="form-input-checkbox"
                                [disabled]="desativarCampos"

                            >
                            <label class="form-label-texts-helper-check">1 - Discordo
                                plenamente</label>
                        </div>
                        <div class="mt-1 d-flex align-items-center">
                            <input
                                [checked]="formGroupEtapa5.get('MudariaNada').value == 2"
                                (click)="toggleCheckboxWidthValue('MudariaNada', 2)"
                                type="checkbox"
                                class="form-input-checkbox"
                                [disabled]="desativarCampos"

                            >
                            <label class="form-label-texts-helper-check">2 - Discordo</label>
                        </div>
                        <div class="mt-1 d-flex align-items-center">
                            <input
                                [checked]="formGroupEtapa5.get('MudariaNada').value == 3"
                                (click)="toggleCheckboxWidthValue('MudariaNada', 3)"
                                type="checkbox"
                                class="form-input-checkbox"
                                [disabled]="desativarCampos"

                            >
                            <label class="form-label-texts-helper-check">3 - Discordo um pouco</label>
                        </div>
                        <div class="mt-1 d-flex align-items-center">
                            <input
                                [checked]="formGroupEtapa5.get('MudariaNada').value == 4"
                                (click)="toggleCheckboxWidthValue('MudariaNada', 4)"
                                type="checkbox"
                                class="form-input-checkbox"
                                [disabled]="desativarCampos"

                            >
                            <label class="form-label-texts-helper-check">4 - Não concordo nem
                                discordo</label>
                        </div>
                        <div class="mt-1 d-flex align-items-center">
                            <input
                                [checked]="formGroupEtapa5.get('MudariaNada').value == 5"
                                (click)="toggleCheckboxWidthValue('MudariaNada', 5)"
                                type="checkbox"
                                class="form-input-checkbox"
                                [disabled]="desativarCampos"

                            >
                            <label class="form-label-texts-helper-check">5 - Concordo um pouco</label>
                        </div>
                        <div class="mt-1 d-flex align-items-center">
                            <input
                                [checked]="formGroupEtapa5.get('MudariaNada').value == 6"
                                (click)="toggleCheckboxWidthValue('MudariaNada', 6)"
                                type="checkbox"
                                class="form-input-checkbox"
                                [disabled]="desativarCampos"

                            >
                            <label class="form-label-texts-helper-check">6 - Concordo</label>
                        </div>
                        <div class="mt-1 d-flex align-items-center">
                            <input
                                [checked]="formGroupEtapa5.get('MudariaNada').value == 7"
                                (click)="toggleCheckboxWidthValue('MudariaNada', 7)"
                                type="checkbox"
                                class="form-input-checkbox"
                                [disabled]="desativarCampos"
                            >
                            <label class="form-label-texts-helper-check">7 - Concordo
                                plenamente</label>
                        </div>
                    </div>


                    <div class="col-12 mb-2 mt-4" *ngIf="erroAvancarAnamnese">
                        <div class="alert alert-danger" role="alert">
                            {{ erroAvancarAnamnese }}
                        </div>
                    </div>

                    <div class="col-12 form-group-buttons row" style="margin-left: 20px;">
                        <div class="col-md-3 mb-3 ">
                            <button class="btn btn-secondary btn-block btn-lg form-bnt-voltar"
                                    (click)="handleClickFecharModal()">Fechar
                            </button>
                        </div>
                        <div class="col-md-3 mb-3 ">
                            <button class="btn btn-secondary btn-block btn-lg form-bnt-voltar"
                                    (click)="handleClickVoltarModal()">Voltar
                            </button>
                        </div>

                        <div class="col-md-6 mb-3" *ngIf="!carregandoAtualizarAnamnese">
                            <button class="btn btn-primary btn-block btn-sm form-bnt-salvar"
                                    (click)="handleClickProximoModal()">Próximo
                            </button>
                        </div>

                        <div class="col-md-6 mb-3" *ngIf="carregandoAtualizarAnamnese">
                            <button class="btn btn-primary btn-block btn-sm form-bnt-salvar"
                                    (click)="handleClickProximoModal()" disabled>Carregando
                            </button>
                        </div>
                    </div>


                </div>
            </form>
        </div>
    </div>
</div>
