import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {AppService} from '@services/app.service';
import {AnamneseService} from '@services/dashboard/anamnese/anamnese.service';
import {ModalCheckBoxes3SonoComponent} from '../Modal9-CheckBoxes3Sono/modal-checkBoxes3Sono.component';
import {ToastrService} from 'ngx-toastr';
import {
    ModalBioConfirmarEnvioComponent
} from "@components/dashboard/modais/Modal-BioConfirmarEnvio/modal-bioConfirmarEnvio.component";
import {Router} from "@angular/router";
import {ModalPercepSaudeComponent} from "@components/dashboard/modais/Modal6-PercepSaude/modal-percepSaude.component";

@Component({
    selector: 'app-modal-retaFinal',
    templateUrl: './modal-retaFinal.component.html',
    styleUrls: ['./modal-retaFinal.component.scss']
})
export class ModalRetaFinalComponent implements OnInit {
    public erroAvancarAnamnese: string | any = ""
    public carregandoAtualizarAnamnese: boolean = false
    public formGroupEtapa10: FormGroup
    public desativarCampos: boolean = false


    opcoesConcordancia = [{
        label: "Discordo plenamente", value: 1
    }, {
        label: "Discordo", value: 2
    }, {
        label: "Discordo um pouco", value: 3
    }, {
        label: "Não concordo e nem discordo", value: 4
    }, {
        label: "Concordo um pouco", value: 5
    }, {
        label: "Concordo", value: 6
    }, {
        label: "Concordo plenamente", value: 7
    },]

    opcoesSemelhancaComigo = [{
        label: "Nada parecido comigo", value: 1
    }, {
        label: "Pouco parecido comigo", value: 2
    }, {
        label: "Algo parecido comigo", value: 3
    }, {
        label: "Bastante parecido comigo", value: 4
    }, {
        label: "Muito parecido comigo", value: 5
    },]

    opcoesSimNao = [{
        label: "Sim", value: true
    }, {
        label: "Não", value: false
    }]

    opcoesSimNaoSuicidio = [{
        label: "Sim", value: 1
    }, {
        label: "Não", value: 2
    }]

    opcoesClassificacaoFezes = [{
        label: "1 - Pequenas bolinhas", value: 1
    }, {
        label: "2 - Pequenas grumos", value: 2
    }, {
        label: "3 - Fezes alongadas com fissuras", value: 3
    }, {
        label: "4 - Fezes alongadas lisas", value: 4
    }, {
        label: "5 - Pedaços macios", value: 5
    }, {
        label: "6 - Massa pastosa", value: 6
    }, {
        label: "7 - Fezes líquidas", value: 7
    },]

    constructor(private appService: AppService, private anamneseService: AnamneseService, private toastr: ToastrService, private router: Router) {
    }


    ngOnInit(): void {
        this.desativarCampos = this.router.url.includes('acesso-avaliador')
        const anamnese = this.anamneseService.getAnamnese()

        this.formGroupEtapa10 = new FormGroup({
            NumeroEtapa: new FormControl({value: anamnese?.Etapa10?.NumeroEtapa, disabled: this.desativarCampos}),
            AnamneseID: new FormControl({value: anamnese?.Etapa10?.AnamneseID, disabled: this.desativarCampos}),
            AgradecerVida: new FormControl({value: anamnese?.Etapa10?.AgradecerVida, disabled: this.desativarCampos}),
            ListaMuitoLonga: new FormControl({
                value: anamnese?.Etapa10?.ListaMuitoLonga, disabled: this.desativarCampos
            }),
            MotivosAgradecer: new FormControl({
                value: anamnese?.Etapa10?.MotivosAgradecer, disabled: this.desativarCampos
            }),
            MuitaGente: new FormControl({value: anamnese?.Etapa10?.MuitaGente, disabled: this.desativarCampos}),
            HistoriaVida: new FormControl({value: anamnese?.Etapa10?.HistoriaVida, disabled: this.desativarCampos}),
            AlguemAlgumaCoisa: new FormControl({
                value: anamnese?.Etapa10?.AlguemAlgumaCoisa, disabled: this.desativarCampos
            }),
            EscalaBristol: new FormControl({value: anamnese?.Etapa10?.EscalaBristol, disabled: this.desativarCampos}),
            Perdao: new FormControl({value: anamnese?.Etapa10?.Perdao, disabled: this.desativarCampos}),
            SuicidioPensou: new FormControl({value: anamnese?.Etapa10?.SuicidioPensou, disabled: this.desativarCampos}),
            SuicidioUltimoAno: new FormControl({
                value: anamnese?.Etapa10?.SuicidioUltimoAno, disabled: this.desativarCampos
            }),
            SuicidioTentou: new FormControl({value: anamnese?.Etapa10?.SuicidioTentou, disabled: this.desativarCampos}),
            CodigoSuicidio: new FormControl({value: anamnese?.Etapa10?.CodigoSuicidio, disabled: this.desativarCampos}),
            DistraemAnteriores: new FormControl({
                value: anamnese?.Etapa10?.DistraemAnteriores, disabled: this.desativarCampos
            }),
            NaoDesanimam: new FormControl({value: anamnese?.Etapa10?.NaoDesanimam, disabled: this.desativarCampos}),
            PerdiInteresse: new FormControl({value: anamnese?.Etapa10?.PerdiInteresse, disabled: this.desativarCampos}),
            MuitoTrabalhador: new FormControl({
                value: anamnese?.Etapa10?.MuitoTrabalhador, disabled: this.desativarCampos
            }),
            SubstituoOutra: new FormControl({value: anamnese?.Etapa10?.SubstituoOutra, disabled: this.desativarCampos}),
            SeremConcluidos: new FormControl({
                value: anamnese?.Etapa10?.SeremConcluidos, disabled: this.desativarCampos
            }),
            QueConheco: new FormControl({value: anamnese?.Etapa10?.QueConheco, disabled: this.desativarCampos}),
            SouEsforcado: new FormControl({value: anamnese?.Etapa10?.SouEsforcado, disabled: this.desativarCampos}),
            DataInicio: new FormControl(new Date()),
            DataFim: new FormControl(null),
            Termo: new FormControl(null)
        })
    }

    handleClickFecharModal() {
        this.appService.closeModal()
    }

    handleClickVoltarModal() {
        this.appService.closeModal()
        this.appService.openModal(ModalCheckBoxes3SonoComponent, {size: 'lg', backdrop: false})
    }

    handleClickProximoModal() {
        // caso os campos esteja desativados,
        // significa que é o professor acessando a tela
        // E ele só pode visualizar as informacoes
        // Logo, a gente nao atualiza a anamnese, apenas
        // Redireciona para a nova tela
        if (this.desativarCampos) {
            this.appService.closeModal()
            this.appService.openModal(ModalBioConfirmarEnvioComponent)
            return
        }


        if (this.formGroupEtapa10.getRawValue()?.Termo != true) {
            this.toastr.error("Necessário aceitar os termos para prosseguir")
            return
        }

        const anamnese = this.anamneseService.getAnamnese()
        const classeContexto = this

        this.carregandoAtualizarAnamnese = true

        const novaAnamneseAtualizada = {
            ...anamnese, Etapa10: {
                ...anamnese?.Etapa10, ...this.formGroupEtapa10.getRawValue(), DataFim: new Date(),
                //SuicidioPensou for 2, SuicidioUltimoAno e SuicidioTentou é 2
                SuicidioUltimoAno: this.formGroupEtapa10.getRawValue()?.SuicidioPensou == 2 ? 2 : this.formGroupEtapa10.getRawValue()?.SuicidioUltimoAno,
                SuicidioTentou: this.formGroupEtapa10.getRawValue()?.SuicidioPensou == 2 ? 2 : this.formGroupEtapa10.getRawValue()?.SuicidioTentou, 
            }
        }

        // primeiro, validamos a anamnese, para ver se os campos obrigatórios foram todos preenchidos
        // Caso estejam preenchidos, deixamos avançar
        this.anamneseService.validarAnamnese(novaAnamneseAtualizada).subscribe({
            next(response) {
                // verifico se há etapas inválidas (endpoint retorna 200 mesmo pra dados inválidos)
                // @ts-ignore
                if (typeof response?.etapaInvalida?.mensagem == 'string' && response?.etapaInvalida?.etapa === 10) {
                    // @ts-ignore
                    classeContexto.erroAvancarAnamnese = response?.etapaInvalida?.mensagem
                    classeContexto.carregandoAtualizarAnamnese = false
                    return
                }

                // atualiza a anamnese se tudo estiver ok
                classeContexto.anamneseService.putAnamnese(novaAnamneseAtualizada).subscribe({
                    next(response2) {

                        classeContexto.carregandoAtualizarAnamnese = false
                        classeContexto.anamneseService.setAnamnese(response2)
                        classeContexto.appService.closeModal()
                        classeContexto.appService.openModal(ModalBioConfirmarEnvioComponent)
                    }, error(response) {
                        classeContexto.carregandoAtualizarAnamnese = false
                        if (typeof response?.error?.Message == 'string') {
                            classeContexto.erroAvancarAnamnese = response?.error?.Message
                        } else {
                            classeContexto.erroAvancarAnamnese = "Ocorreu um erro ao atualizar a anamnese"
                        }
                    }
                })

            }, error(erro) {
                classeContexto.carregandoAtualizarAnamnese = false
                if (typeof erro?.Message == 'string') {
                    classeContexto.erroAvancarAnamnese = erro?.Message
                } else {
                    classeContexto.erroAvancarAnamnese = "Ocorreu um erro ao validar as informações preenchidas"
                }
            }
        })
    }

}
