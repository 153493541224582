import { DateTime } from 'luxon';
import { Component } from '@angular/core';
import { ProfessorService } from '@services/dashboard/professor/professor.service';
import { ToastrService } from 'ngx-toastr';
import { AnamneseService } from '@services/dashboard/anamnese/anamnese.service';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-usuarios-alterar',
  templateUrl: './usuarios-alterar.html',
  styleUrls: ['./usuarios-alterar.scss']
})
export class UsuariosAlterar {
  public stepperSelected: string = 'Cadastro';
  public steppers = [
    { name: 'Cadastro', isSelected: false },
    { name: 'Plano', isSelected: false },
    { name: 'Avaliadores', isSelected: false },
    { name: 'CHECKUPS', isSelected: false },
   ];


  public opcoesSexo = [
    { value: 3, label: 'Não Informar' },
    { value:  1, label: 'Masculino' },
    { value: 2, label: 'Feminino' },
  ];

  public opcoesEstadoCivil = [
    { value: 1, label: 'Solteiro(a)' },
    { value: 2, label: 'Casado(a)' },
    { value: 3, label: 'União Estável' },
    { value: 4, label: 'Divorciado(a)' },
    { value: 5, label: 'Viúvo(a)' },
    { value: 6, label: 'Outros' },
  ];

  public opcoesRaca = [
    { value: 0, label: 'Não Informar' },
    { value: 1, label: 'Caboclo(a)' },
    { value: 2, label: 'Mulato(a)' },
    { value: 3, label: 'Cafuzo(a)' },
    { value: 4, label: 'Branco(a)' },
    { value: 5, label: 'Negro(a)' },
    { value: 6, label: 'Índio(a)' }
  ]

  public grauEscolaridade = [
    { value: 0, label: 'Não Informar' },
    { value: 1, label: 'Ensino Fundamental' },
    { value: 2, label: 'Ensino Médio' },
    { value: 3, label: 'Ensino Superior' },
    { value: 4, label: 'Pós Graduação' },
    { value: 5, label: 'Doutorado' },
    { value: 6, label: 'Pós Doutorado' }
  ]

  public opcoesPerfil = [
    { value: 1, label: 'Administrador Geral' },
    { value: 2, label: 'Cliente' },
    { value: 3, label: 'Avaliador' },
    { value: 4, label: 'Avaliador/Cliente' },
  ]

  public opcoesCorrespondencia = [
    { value: true, label: 'Sim' },
    { value: false, label: 'Não' },
  ]

  public etapa1Data = new FormGroup({
    ID: new FormControl(''),
    AceitaCorrespondencia: new FormControl(''),
    Ativo: new FormControl(true),
    Nome: new FormControl(''),
    Login: new FormControl(''),
    TelefoneCelular: new FormControl(''),
    Telefone: new FormControl(''),
    Email: new FormControl(''),
    DataNascimento: new FormControl(''),
    Ocupacao: new FormControl(''),
    Sexo: new FormControl(''),
    EstadoCivil: new FormControl(''),
    Raca: new FormControl(''),
    GrauEscolaridade: new FormControl(''),
    CEP: new FormControl(''),
    Cidade: new FormControl(''),
    UFEndereco: new FormControl(''),
    Bairro: new FormControl(''),
    Logradouro: new FormControl(''),
    Numero: new FormControl(''),
    PerfilID: new FormControl(''),
    Complemento: new FormControl(''),
    PlanoID: new FormControl(''),
    IdentificadorHash: new FormControl(''),
    UsuarioInclusao: new FormControl(''),
    PerfilIDPreferencial: new FormControl(''),
    DataInclusao: new FormControl(''),
    UsuarioAlteracao: new FormControl(''),
    DataAlteracao: new FormControl(''),
    Idade: new FormControl(''),
  })
  public etapa2Data: FormGroup
  public etapa3Data: FormGroup

  public listaAvaliadores: any;
  public isLoadingSalvar: any;
   public listaCheckupsCliente: any;
  public listaClientesAvaliador: any;
  public listaPlanosAtivos: any;
  public nenhumAvaliadorEncontrado: boolean = false;
  public loadingListaClientesAvaliadores: boolean = false;
  public loadingListaAvaliadores: boolean = false;
  public nenhumCheckupEncontrado: boolean = false;
  public loadingListaCheckups: boolean = false;
  public nenhumClienteEncontrado: boolean = false;

  public loadingDadosUsuario: boolean = false;




  constructor(private professorService: ProfessorService,private toastr: ToastrService,
    private anamneseService: AnamneseService) {}

  ngOnInit(): void {

    this.getCheckupsCliente();
    this.getClientesAvaliador();
    this.getDadosUsuario();
    this.getListaPlanosAtivos();
  }


  //obtem checkups do cliente
  getCheckupsCliente(): void {
    let id = window.location.href.split('/').pop();
    this.professorService.getObterCheckupsUsuario(id).subscribe({
      next: (response) => {
        this.listaCheckupsCliente = response;
      },
      error: (error) => {
        if (error?.error?.Message) {
          this.toastr.error(error.error.Message);
        } else {
          this.toastr.error('Erro ao buscar avaliadores, contate o suporte')
        }
      }
    });
  }
  //obtem lista de clientes avaliador
  getClientesAvaliador(): void {
    let id = window.location.href.split('/').pop();
    this.loadingListaClientesAvaliadores = true;
    this.professorService.getObterListaClientes(id).subscribe({
      next: (response: any) => {
        this.listaClientesAvaliador = response;
        this.loadingListaClientesAvaliadores = false;
        if (response.length === 0) {
          this.nenhumClienteEncontrado = true;
        }
      },
      error: (error) => {
        this.loadingListaClientesAvaliadores = false;
        if (error?.error?.Message) {
          this.toastr.error(error.error.Message);
        } else {
          this.toastr.error('Erro ao buscar clientes, contate o suporte')
        }
      }
    });
  }

  //obtem lista de planos ativos
  getListaPlanosAtivos(): void {
    this.professorService.getListaPlanosAtivos().subscribe({
      next: (response) => {
        this.listaPlanosAtivos = response;
        console.log(response);
       },
      error: (error) => {
        if (error?.error?.Message) {
          this.toastr.error(error.error.Message);
        } else {
          this.toastr.error('Erro ao buscar planos ativos, contate o suporte')
        }
      }
    });
  }

  //obtem dados do usuario
  getDadosUsuario(): void {
    let id = window.location.href.split('/').pop();
    this.loadingDadosUsuario = true;
    this.professorService.getObterDadosCadastro(id).subscribe({
      next: (response : any) => {
        this.etapa1Data = new FormGroup({
          ID: new FormControl(response.ID),
          AceitaCorrespondencia: new FormControl(response.AceitaCorrespondencia),
          Ativo: new FormControl(response.Ativo),
          Nome: new FormControl(response.Nome),
          Login: new FormControl(response.Login),
          TelefoneCelular: new FormControl(response.TelefoneCelular),
          Telefone: new FormControl(response.Telefone),
          Email: new FormControl(response.Email),
          DataNascimento: new FormControl(DateTime.fromISO(response.DataNascimento).toISODate()),
          Ocupacao: new FormControl(response.Ocupacao),
          Sexo: new FormControl(response.Sexo),
          EstadoCivil: new FormControl(response.EstadoCivil),
          Raca: new FormControl(response.Raca),
          GrauEscolaridade: new FormControl(response.GrauEscolaridade),
          CEP: new FormControl(response.CEP),
          Cidade: new FormControl(response.Cidade),
          UFEndereco: new FormControl(response.UFEndereco),
          Bairro: new FormControl(response.Bairro),
          Logradouro: new FormControl(response.Logradouro),
          Numero: new FormControl(response.Numero),
          PerfilID: new FormControl(response.PerfilID),
          Complemento: new FormControl(response.Complemento),
          PlanoID: new FormControl(response.PlanoID),
          Idade: new FormControl(response.Idade),
          IdentificadorHash: new FormControl(response.IdentificadorHash),
          PerfilIDPreferencial: new FormControl(response.PerfilID),
          UsuarioInclusao: new FormControl(response?.Avaliadores[0]?.Avaliador?.UsuarioInclusao),
          DataInclusao: new FormControl(response?.Avaliadores[0]?.Avaliador?.DataInclusao),
          UsuarioAlteracao: new FormControl(response?.Avaliadores[0]?.Avaliador?.UsuarioAlteracao),
          DataAlteracao: new FormControl(response?.Avaliadores[0]?.Avaliador?.DataAlteracao),

        });
        this.listaAvaliadores = response?.Avaliadores;
        if (response?.Avaliadores.length === 0) {
          this.nenhumAvaliadorEncontrado = true;
        }
        if (response.PerfilID !== 2) {
           this.steppers = [
            ...this.steppers,
            { name: 'Vidas Cadastradas', isSelected: false },
          ]

        }
        this.loadingDadosUsuario = false;
      },
      error: (error) => {
        if (error?.error?.Message) {
          this.toastr.error(error.error.Message);
        } else {
          this.toastr.error('Erro ao buscar dados do usuário, contate o suporte')
        }
        this.loadingDadosUsuario = false;
      }
    });
  }



  onSelectStepper(stepperSelected: string) {
    this.stepperSelected = stepperSelected;

  }

  onSalvar() {


    let dadosParaSalvar = {
      ...this.etapa1Data.value,
      DataNascimento: DateTime.fromISO(this.etapa1Data.value.DataNascimento).toISODate(),
      PerfilID: parseInt(this.etapa1Data.value.PerfilID),
      Sexo: parseInt(this.etapa1Data.value.Sexo),
      EstadoCivil: parseInt(this.etapa1Data.value.EstadoCivil),
      Raca: parseInt(this.etapa1Data.value.Raca),
      GrauEscolaridade: parseInt(this.etapa1Data.value.GrauEscolaridade),
      AceitaCorrespondencia: this.etapa1Data.value.AceitaCorrespondencia === 'true' ? true : false,
      Ativo: this.etapa1Data.value.Ativo ? true : false,
      Plano: this.listaPlanosAtivos.find((plano: any) => plano.ID == this.etapa1Data.value.PlanoID),
      PerfilIDPreferencial: parseInt(this.etapa1Data.value.PlanoID),
    }
    console.log(dadosParaSalvar);


    this.isLoadingSalvar = true;
    this.professorService.putEditarUsuario(dadosParaSalvar).subscribe({
      next: (res: any) => {
        this.toastr.success('Usuário salvo com sucesso');
        this.isLoadingSalvar = false;
      },
      error: (erro: any) => {
        if (erro && erro.error && erro.error.Message) {
          const messages = JSON.parse(erro.error.Message);
          if (Array.isArray(messages)) {
            // Itera sobre cada mensagem de erro e exibe no Toastr
            messages.forEach((mensagem: any) => {
              if (mensagem.Mensagem) {
                this.toastr.error(mensagem.Mensagem, 'Erro ao salvar os dados');
              }
            });
          }
        } else {
          // Caso o formato de erro seja inesperado, exibe uma mensagem genérica
          this.toastr.error('Erro ao salvar os dados. Tente novamente mais tarde.');
          console.error('Erro ao processar a resposta de erro:', erro);
        }
        this.isLoadingSalvar = false;
      }
    });

  }

  onSair() {
    window.history.back();
  }

  onRemoverClienteAvaliador(avaliador: any) {
    let data = {
      ClienteID: avaliador?.ClienteID,
      AvaliadorID: avaliador?.AvaliadorID,
    }

    this.professorService.postRemoverClienteAvaliador(data).subscribe({
      next: (response) => {
        this.toastr.success('Avaliador removido com sucesso');
        this.getClientesAvaliador();
      },
      error: (error) => {
        if (error?.error?.Message) {
          this.toastr.error(error.error.Message);
        } else {
          this.toastr.error('Erro ao remover avaliador, contate o suporte')
        }
      }
    });
  }

  onVisualizarLaudo(checkup: any) {
    window.open(`https://api.plataformasafe.com.br/Home/DownloadLaudoCompleto/${checkup.AnamneseHash}`, '_blank');
  }


  onNovoCheckup(avaliador: any) {
    let data = {
      ClienteID: avaliador?.ClienteID,
      AvaliadorID: avaliador?.AvaliadorID,
      GrupoID: null,
      Presencial: false,
      Tipo: 1,
      TipoLaudo: 3,
    }
    this.professorService.postNovoCheckup(data).subscribe({
      next: (response) => {
        this.toastr.success('Checkup registrado com sucesso');
        this.getCheckupsCliente();
      },
      error: (error) => {
        if (error?.error?.Message) {
          this.toastr.error(error.error.Message);
        } else {
          this.toastr.error('Erro ao registrar checkup, contate o suporte')
        }
      }
    });

  }

  formatDate(date) {
    return DateTime.fromISO(date).toFormat('dd/MM/yyyy');
  }


}



