import {Component, OnDestroy, OnInit} from '@angular/core';
import {AppService} from '@services/app.service';
import {Modal1InicioComponent} from '../modal1-Inicio/modal-1inicio.component';
import {FormControl, FormGroup} from '@angular/forms';
import {AnamneseService} from '@services/dashboard/anamnese/anamnese.service';
import {createMask} from '@ngneat/input-mask';
import {
    ModalInfoSobreSaudeComponent
} from "@components/dashboard/modais/modal3-InfoSobreSaude/modal-infoSobreSaude.component";
import {formatarDataParaDDMMYYYY} from "@/utils/mascaras";
import {Router} from "@angular/router";
import {Subscription} from "rxjs";

@Component({
    selector: 'app-modal-dadospessoais',
    templateUrl: './modal-dadospessoais.component.html',
    styleUrls: ['./modal-dadospessoais.component.scss']
})
export class ModalDadosPessoaisComponent implements OnInit, OnDestroy {
    public erroAvancarAnamnese: string | any = "";
    public carregandoAtualizarAnamnese: boolean = false;
    public formGroupEtapa2: FormGroup;
    public opcoesPeso: number[] = [];
    public listaFilhos = [];
    public desativarCampos: boolean = false;
    public alunoIdSubscription: Subscription;
    dateInputMask = createMask<Date>({
        alias: 'datetime',
        inputFormat: 'dd/mm/yyyy',
        parser: (value: string) => {
            const values = value.split('/');
            const year = +values[2];
            const month = +values[1] - 1;
            const date = +values[0];
            return new Date(year, month, date);
        },
    });

    constructor(
        private appService: AppService,
        private anamneseService: AnamneseService,
        private router: Router
    ) {
    }

    ngOnDestroy() {
        this.alunoIdSubscription.unsubscribe();
    }

    ngOnInit(): void {
        this.desativarCampos = this.router.url.includes('acesso-avaliador');

        const anamnese = this.anamneseService.getAnamnese();
        this.formGroupEtapa2 = new FormGroup({
            NumeroEtapa: new FormControl({value: 2, disabled: this.desativarCampos}),
            AnamneseID: new FormControl({value: anamnese?.Etapa2?.AnamneseID, disabled: this.desativarCampos}),
            Peso: new FormControl({value: anamnese?.Etapa2?.Peso, disabled: this.desativarCampos}),
            Altura: new FormControl({value: anamnese?.Etapa2?.Altura, disabled: this.desativarCampos}),
            Pressao: new FormControl({value: anamnese?.Etapa2?.Pressao, disabled: this.desativarCampos}),
            PressaoPart1: new FormControl({value: anamnese?.Etapa2?.PressaoPart1, disabled: this.desativarCampos}),
            PressaoPart2: new FormControl({value: anamnese?.Etapa2?.PressaoPart2, disabled: this.desativarCampos}),
            Gestante: new FormControl({value: anamnese?.Etapa2?.Gestante, disabled: this.desativarCampos}),
            MesesGestacao: new FormControl({value: anamnese?.Etapa2?.MesesGestacao, disabled: this.desativarCampos}),
            TemConjuge: new FormControl({value: !!anamnese?.Etapa2?.TemConjuge, disabled: this.desativarCampos}),
            DataNascConjuge: new FormControl(
                {
                    value: anamnese?.Etapa2?.DataNascConjuge ? this.formataDataParaAmericana(anamnese?.Etapa2?.DataNascConjuge) : null,
                    disabled: this.desativarCampos
                }
            ),
            DatasNascFilhosTexto: new FormControl({
                value: anamnese?.Etapa2?.DatasNascFilhosTexto,
                disabled: this.desativarCampos
            }),
            DatasNascFilhos: new FormControl({
                value: anamnese?.Etapa2?.DatasNascFilhos,
                disabled: this.desativarCampos
            }),
            FazendoDieta: new FormControl({value: anamnese?.Etapa2?.FazendoDieta, disabled: this.desativarCampos}),
            TemNutricionista: new FormControl({
                value: anamnese?.Etapa2?.TemNutricionista,
                disabled: this.desativarCampos
            }),
            DescricaoDieta: new FormControl({value: anamnese?.Etapa2?.DescricaoDieta, disabled: anamnese?.Etapa2?.FazendoDieta ? false : true}),
            ProblemaCardiaco: new FormControl({
                value: anamnese?.Etapa2?.ProblemaCardiaco,
                disabled: this.desativarCampos
            }),
            DorToraxAtivFisica: new FormControl({
                value: anamnese?.Etapa2?.DorToraxAtivFisica,
                disabled: this.desativarCampos
            }),
            UltimoMesDorTorax: new FormControl({
                value: anamnese?.Etapa2?.UltimoMesDorTorax,
                disabled: this.desativarCampos
            }),
            PerdeuEquilibrio: new FormControl({
                value: anamnese?.Etapa2?.PerdeuEquilibrio,
                disabled: this.desativarCampos
            }),
            ProblemaOsseo: new FormControl({value: anamnese?.Etapa2?.ProblemaOsseo, disabled: this.desativarCampos}),
            RemedioPressao: new FormControl({value: anamnese?.Etapa2?.RemedioPressao, disabled: this.desativarCampos}),
            FisicoImpedeAtivFisica: new FormControl({
                value: anamnese?.Etapa2?.FisicoImpedeAtivFisica,
                disabled: this.desativarCampos
            }),
            TesteEsforco: new FormControl({value: anamnese?.Etapa2?.TesteEsforco, disabled: this.desativarCampos}),
            NivelAtividadeFisica: new FormControl({
                value: anamnese?.Etapa2?.NivelAtividadeFisica,
                disabled: this.desativarCampos
            }),
            Tabagista: new FormControl({value: anamnese?.Etapa2?.Tabagista, disabled: this.desativarCampos}),
            QtdCigarros: new FormControl({value: anamnese?.Etapa2?.QtdCigarros, disabled: this.desativarCampos}),
            DataInicio: new FormControl({value: new Date(), disabled: this.desativarCampos}),
            DataFim: new FormControl({value: anamnese?.Etapa2?.DataFim, disabled: this.desativarCampos}),

            // campos por fora (temporarios para armazenar a data no DatasNascFilhos
            temFilhos: new FormControl({
                value: anamnese?.Etapa2?.DatasNascFilhos?.length > 0,
                disabled: this.desativarCampos
            }),
            dataNascimentoFilho: new FormControl({value: '', disabled: this.desativarCampos})
        });

        this.formGroupEtapa2.get('QtdCigarros').valueChanges.subscribe((value) => {
            this.formGroupEtapa2.get('QtdCigarros').setValue(value.replace(/\D/g, ''), {emitEvent: false});
        });
        this.formGroupEtapa2.get('FazendoDieta').valueChanges.subscribe((value) => {
             //habilita ou desabilita o campo de descrição da dieta
            if(value){
                this.formGroupEtapa2.get('DescricaoDieta').enable();
            }
            else{
                this.formGroupEtapa2.get('DescricaoDieta').disable();
                this.formGroupEtapa2.get('DescricaoDieta').setValue('');
                this.formGroupEtapa2.get('TemNutricionista').setValue(false);
            }
        });

        // aceita apenas números e ponto
        this.formGroupEtapa2.get('Pressao').valueChanges.subscribe((value) => {
            this.formGroupEtapa2.get('Pressao').setValue(value.replace(/[^0-9.]/g, ''), {emitEvent: false});
        });

    

        // atualiza o valor do array de filhos ao carregar a página
        this.listaFilhos = this.formGroupEtapa2.get('DatasNascFilhos').value?.map(data => ({
            id: Math.random() * 100000,
            value: data
        }));
    }

    formataDataParaAmericana(data) {
        // recebe 2024-04-12T00:00:00
        // retorna 2024-04-12
        return data.split('T')[0];
    }

    adicionarFilho() {
        const dataNascimentoFilho = this.formGroupEtapa2.get('dataNascimentoFilho').value;
        this.listaFilhos.push({id: Math.random() * 10000000, value: dataNascimentoFilho});
        this.formGroupEtapa2.get('DatasNascFilhos').setValue(this.listaFilhos);
    }

    deletarFilho(id) {
        this.listaFilhos = this.listaFilhos.filter(f => f.id != id);
        this.formGroupEtapa2.get('DatasNascFilhos').setValue(this.listaFilhos);
    }

    toggleCheckbox(checkboxName, newValue = false) {
        this.formGroupEtapa2.get(checkboxName).setValue(newValue);
    }

    toggleCheckboxWidthValue(checkboxName: string, value: any) {
        this.formGroupEtapa2.get(checkboxName).setValue(value);
    }

    formatarDataNascimento(data) {
        let dataFormatada = data.split('T')[0];
        return formatarDataParaDDMMYYYY(dataFormatada);
    }

    

    handleClickFecharModal() {
        this.appService.closeModal();
    }

    handleClickVoltarModal() {
        this.appService.closeModal();
        const modalRef = this.appService.openModal(Modal1InicioComponent, {size: 'lg', backdrop: false});
    }

    handleClickProximoModal() {
        // Format the weight before submission
        //se peso ou altura length for 3, adiciona 00 no final

     

        // caso os campos estejam desativados,
        // significa que é o professor acessando a tela
        // E ele só pode visualizar as informacoes
        // Logo, a gente nao atualiza a anamnese, apenas
        // Redireciona para a nova tela
        if (this.desativarCampos) {
            this.appService.closeModal();
            this.appService.openModal(ModalInfoSobreSaudeComponent);
            return;
        }

        const anamnese = this.anamneseService.getAnamnese();
        const classeContexto = this;

        this.carregandoAtualizarAnamnese = true;
       
  
 
        const novaAnamneseAtualizada = {
            ...anamnese,
            Etapa2: {
                ...anamnese?.Etapa2,
                ...this.formGroupEtapa2.getRawValue(),
                DataFim: new Date(),
                DatasNascFilhos: this.listaFilhos.map(filho => filho.value),
                dataNascimentoFilho: undefined,
                // Peso: parseFloat( this.formGroupEtapa2.get('Peso').value),
                // Altura: parseFloat(alturaAtualizada),
            }
        };

        // primeiro, validamos a anamnese, para ver se os campos obrigatórios foram todos preenchidos
        // Caso estejam preenchidos, deixamos avançar
        this.anamneseService.validarAnamnese(novaAnamneseAtualizada).subscribe({
            next(response) {
                // verifico se há etapas inválidas (endpoint retorna 200 mesmo pra dados inválidos)
                // @ts-ignore
                if (typeof response?.etapaInvalida?.mensagem == 'string' && response?.etapaInvalida?.etapa === 2) {
                    // @ts-ignore
                    classeContexto.erroAvancarAnamnese = response?.etapaInvalida?.mensagem;
                    classeContexto.carregandoAtualizarAnamnese = false;
                    return;
                }

                // atualiza a anamnese se tudo estiver ok
                classeContexto.anamneseService.putAnamnese(novaAnamneseAtualizada).subscribe({
                    next(response2) {
                        classeContexto.carregandoAtualizarAnamnese = false;
                        classeContexto.anamneseService.setAnamnese(response2);
                        classeContexto.appService.closeModal();
                        classeContexto.appService.openModal(ModalInfoSobreSaudeComponent);
                    },
                    error(response) {
                        classeContexto.carregandoAtualizarAnamnese = false;
                        if (typeof response?.error?.Message == 'string') {
                            classeContexto.erroAvancarAnamnese = response?.error?.Message;
                        } else {
                            classeContexto.erroAvancarAnamnese = "Ocorreu um erro ao atualizar a anamnese";
                        }
                    }
                });
            },
            error(erro) {
                classeContexto.carregandoAtualizarAnamnese = false;
                if (typeof erro?.Message == 'string') {
                    classeContexto.erroAvancarAnamnese = erro?.Message;
                } else {
                    classeContexto.erroAvancarAnamnese = "Ocorreu um erro ao validar as informações preenchidas";
                }
            }
        });
    }
}
