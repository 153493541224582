import {AppState} from '@/store/state';
import {UiState} from '@/store/ui/state';
import {Component, HostBinding, Input, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {AppService} from '@services/app.service';
import {AnamneseService} from '@services/dashboard/anamnese/anamnese.service';
import {DadosCadastraisService} from '@services/dashboard/dados-cadastrais/dados-cadastrais.service';
import {Observable, Subscription} from 'rxjs';

const BASE_CLASSES = 'main-sidebar d-flex align-items-center';

@Component({
    selector: 'app-menu-sidebar',
    templateUrl: './menu-sidebar.component.html',
    styleUrls: ['./menu-sidebar.component.scss']
})
export class MenuSidebarComponent implements OnInit, OnDestroy {
    @HostBinding('class') classes: string = BASE_CLASSES;
    public ui: Observable<UiState>;
    public user;
    public menu = [];
    public menusBackend = []
    public anamnese: any
    public usuarioLogado = null
    @Input() perfilTipo: number
    public isAvaliador: boolean = false
    public tipoZ: number | any

    public isMenuOpen = false;

    anamneseSubscription: Subscription
    imc = 0

    constructor(public appService: AppService, private store: Store<AppState>, private anamneseService: AnamneseService, public dadosCadastraisService: DadosCadastraisService, private router: Router, private ac: ActivatedRoute) {
    }

    public toggleMenu() {
        this.isMenuOpen = !this.isMenuOpen;
    }


    public toggleMenuMobile() {
        if (window.innerWidth < 926) {
            this.isMenuOpen = !this.isMenuOpen;
        }
    }
  


    ngOnInit() {
        this.anamnese = this.anamneseService.getAnamnese()
        // this.getObterMenus()
        this.ui = this.store.select('ui');
        this.ui.subscribe((state: UiState) => {
            this.classes = `${BASE_CLASSES} ${state.sidebarSkin}`;
        });
        this.user = this.appService.getDadosUsuarioLogadoStorage()
        this.anamneseSubscription = this.anamneseService.ananmese$.subscribe(novoValor => {
            this.anamnese = novoValor

            if (novoValor?.Z) {
                this.tipoZ = `Z` + novoValor?.Z
            } else if (novoValor?.Z == 0) {
                this.tipoZ = `Z` + 0
            } else {
                this.tipoZ = "Sem informação"
            }
        })

        this.usuarioLogado = this.appService.getDadosUsuarioLogadoStorage()
        if (this.usuarioLogado?.ID) {
            this.dadosCadastraisService.getDadosCadastrais(this.usuarioLogado.ID)
        }

        /**
         * Perfil tipo 3 = aluno
         * Perfil 1, 2 e 4 = Avaliador
         *
         */
        const isAvaliador: boolean = this.perfilTipo != 3;
        this.isAvaliador = isAvaliador
        if (isAvaliador) {
            this.montarMenuAvaliador()
        } else {
            this.montarMenuAluno()
        }

    }

    

    mudarPerfil() {
        if (this.router.url.includes('/acesso-avaliador')) {
            this.router.navigate(["/dashboard/acesso-aluno"]);
            return
        }

        if (this.router.url.includes('/acesso-aluno')) {
            this.router.navigate(["/dashboard/acesso-avaliador"]);
            return
        }
    }

    ebooks () {
        this.router.navigate(["/dashboard/acesso-avaliador/ebooks"]);
    }


    portalSafe () {
        window.open('https://www.portal.metodosafe.com.br/auth/login', '_blank');
    }


    inscricaoSafe () {
        window.open('https://imersao.metodosafe.com.br/inscricao', '_blank');
    }


    duvidaSafe () {
        this.router.navigate(["/dashboard/acesso-avaliador/duvidas"]);
    }

    comunidadeSafe () {
        window.open('https://www.facebook.com/groups/2364783083736796', '_blank');
    }

    suporteSafe () {
        window.open('https://api.whatsapp.com/send?phone=556182516727', '_blank');
    }

    inicioAluno() {
        this.router.navigate(["/dashboard/acesso-aluno"]);
    }

    meusDados() {
        this.router.navigate(["/dashboard/acesso-aluno/meus-dados"]);
    }

    meusCheckups() {
        this.router.navigate(["/dashboard/acesso-aluno/meus-checkups"]);
    }

    alterarSenhaAluno() {
        this.router.navigate(["/dashboard/acesso-aluno/alterar-senha-aluno"]);
    }

    // monta o menu lateral do professor
    montarMenuAvaliador() {

        const classeContexto = this
        this.menu = [
            {
                name: "Página Inicial",
                path: ["/dashboard/acesso-avaliador"],
                onclick() {
                    classeContexto.router.navigate(['/dashboard/acesso-avaliador'])
                },
                children: []
            },
            {
                name: "Cadastros", path: ["/dashboard/acesso-avaliador"], onclick: function () {},
                children: [
                    {
                        name: 'Dados Pessoais completos',
                        path: ['/dashboard/acesso-avaliador/usuarios']
                    },
                    {
                        name: 'Meus Clientes',
                        path: ['/dashboard/acesso-avaliador/meus-clientes']
                    },
                    {
                        name: 'Avaliação Física',
                        path: ['/dashboard/acesso-avaliador/usuarios-avaliacoes']
                    },
                    {
                        name: 'Avaliação Mente',
                        path: ['/dashboard/acesso-avaliador/anamneses']
                    },
                    {
                        name: 'Senha do cliente reenviada',
                        path: ['/dashboard/acesso-avaliador/senhas-provisorias']
                    },
                    {
                        name: 'Cadastrar nova avaliação',
                        path: ['/dashboard/acesso-avaliador/checkups']
                    } 
                ]
            },
            {
                name: "Relatórios",
                path: ["/dashboard/acesso-avaliador"],
                onclick: function () {this.router.navigator(['/dashboard/acesso-avaliador'])},
                children: [
                    {
                        name: 'Relatório dos meus CHECK-UPS ',
                        path: ['/dashboard/acesso-avaliador/relatorios/relatorio-evolucao']
                    },
                    {
                        name: 'Biomarcadores SAFE®',
                        path: ['/dashboard/acesso-avaliador/relatorios/biomarcadores-safe']
                    },
                    {
                        name: 'Código para cadastrar meu cliente',
                        path: ['/dashboard/acesso-avaliador/relatorios/pre-cadastro']
                    },
                    {
                        name: 'CHECK-UPS por período',
                        path: ['/dashboard/acesso-avaliador/relatorios/relatorio-checkup-por-periodo']
                    },
                    {
                        name: 'Aniversariantes do Mês',
                        path: ['/dashboard/acesso-avaliador/relatorios/relatorio-aniversariantes-mes']
                    },
                    {
                        name: 'Tabela de Gordura',
                        path: ['/dashboard/acesso-avaliador/tabela-gordura']
                    },{
                        name: 'Auto Consciência',
                        path: ['/dashboard/acesso-avaliador/relatorios/auto-consciencia']
                    },
                ]
            },
            {
                name: "Livros", 
                path: [], 
                onclick: () => this.ebooks(),
                children: []
            },
            {
                name: "SAFEFLIX", 
                path: [], 
                onclick: () => this.portalSafe(),
                children: []
            },
            {
                name: "Inscrição gratuita assinante Imersão SAFE 30, 30 Agosto - 1º Setembro", 
                path: [], 
                textColor: 'text-amarelo-plataforma-safe',
                onclick: () => this.inscricaoSafe(),
                children: []
            },
            {
                name: "Tire sua dúvida aqui", 
                path: [], 
                onclick: () => this.duvidaSafe(),
                children: []
            },
            {
                name: "Comunidade SAFE®", 
                path: [], 
                onclick: () => this.comunidadeSafe(),
                children: []
            },
            {
                name: "Suporte SAFE®", 
                path: [], 
                onclick: () => this.suporteSafe(),
                children: []
            },
            {
                name: "Sair",
                onclick: () => this.appService.logout(),
                children: [],
                path: []
            },
        ]

        if (this.user?.Perfil?.ID == 4 || this.user?.Perfil?.ID == 1) {
            this.menu.splice(1, 0, {
                name: "Mudar Perfil",
                path: ["/dashboard/acesso-avaliador"],
                onclick: () => this.mudarPerfil(),
                children: []
            })
        }

        if ( this.user?.Perfil?.ID == 1) {
            //adiciona o menu de cupons em cadastros
            this.menu[2].children.push({
                name: 'Meus Links',
                path: ['/dashboard/acesso-avaliador/cupons']
            })
        }
            

    }

    // monta o menu lateral do aluno
    montarMenuAluno() {
        this.menu = [
            {
              name: "Página Inicial", path: ["/dashboard/acesso-aluno"], children: [], onclick: () => this.inicioAluno()
            }, 
            
            {
               name: "CHECK-UPS", path: ["/dashboard/acesso-aluno/meus-checkups"], children: [], onclick: () => this.meusCheckups()
             }, 
            {
               name: "Alterar Senha", path: ["/dashboard/acesso-aluno/alterar-senha-aluno"], children: [], onclick: () => this.alterarSenhaAluno()
             }, 
            {
                name: "Sair", children: [], onclick: () => this.appService.logout(), path: []
            },]

            if (this.user?.Perfil?.ID == 4 || this.user?.Perfil?.ID == 1) {
                this.menu.splice(1, 0, {
                name: "Mudar Perfil", path: ["/dashboard/acesso-aluno"], onclick: () => this.mudarPerfil(), children: []
            })}
            if (this.user?.Perfil?.ID == 3 || this.user?.Perfil?.ID == 2) {
                this.menu.splice(2, 0, {
                    name: "Meus Dados", path: ["/dashboard/acesso-aluno/meus-dados"], onclick: () => this.meusDados(), children: []
            })}
           
    }

    logout() {
        this.appService.logout();
    }

    ngOnDestroy() {
        this.anamneseSubscription.unsubscribe()
    }

    /**
     *
     * Essa funcao pega os menus da API (do sistema antigo)
     *
     */
    getObterMenus() {
        this.appService.getObterMenus().subscribe({
            next: (response: Array<any>) => {

                const menus = []
                for (const menu of response) {

                    // verifico se o menu existe pela Categoria (Só pode haver 1 categoria no menu)
                    let menuExiste = menus.find(m => m.category == menu?.Categoria)

                    if (!menuExiste) {
                        // se ele não existir, crio um menu virtual
                        menuExiste = {
                            category: menu?.Categoria, name: menu?.Categoria, iconClasses: menu?.Icone, children: []
                        }


                        // depois, adiciono nesse menu virtual todos os submenus dele, de acordo com a categoria
                        for (const submenu of response) {

                            if (menu?.Categoria == submenu?.Categoria) {
                                menuExiste.children.push({
                                    name: submenu?.Nome,
                                    iconClasses: submenu?.Icone,
                                    path: [this.formatarURLObterMenus(submenu?.Url)]
                                })
                            }
                        }

                        // adiciono o menu virtual a lista de menus (com todos teus respectivos submenus)
                        menus.push(menuExiste)
                    }
                }
                this.menu = menus
            }, error: error => {
            }
        })
    }


    /**
     *
     *
     * URL armazena a URL do sistema antigo (que é meio zuada)
     * Aqui a gente converte pra uma URL que da pra usar no sistema novo
     *
     */
    formatarURLObterMenus(url: string | undefined) {
        if (!url) return ''
        const urlFormatada = url.split('Dashboard/#!')[1]
        return `/dashboard/${urlFormatada}`
    }


    pegarPrimeiroNomeESegundoNomeDaString(nome: string | undefined) {
        if (!nome) return ''

        // Divide o nome completo em partes separadas pelo espaço em branco
        const partesNome = nome.trim().split(' ');

        // Verifica se há mais de um elemento no array partesNome
        if (partesNome.length > 1) {
            // Se houver mais de um elemento, retorna o primeiro nome e o primeiro sobrenome
            return partesNome[0] + ' ' + partesNome[partesNome.length - 1];
        } else {
            // Se houver apenas um elemento, retorna somente o nome
            return partesNome[0];
        }
    }
}

export const MENU = [{
    name: 'Dashboard', iconClasses: 'fas fa-tachometer-alt', path: ['/']
}, {
    name: 'Blank', iconClasses: 'fas fa-file', path: ['/blank']
}, {
    name: 'Main Menu', iconClasses: 'fas fa-folder', children: [{
        name: 'Sub Menu', iconClasses: 'far fa-address-book', path: ['/sub-menu-1']
    }, {
        name: 'Blank', iconClasses: 'fas fa-file', path: ['/sub-menu-2']
    }]
}];
