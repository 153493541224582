import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from "@angular/forms";
import { ProfessorService } from "@services/dashboard/professor/professor.service";
import { RelatorioCadastroService } from '@services/relatorios/relatorio-cadastros/relatorio-cadastros.service';
import { addDays, isValid } from 'date-fns';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { ToastrService } from 'ngx-toastr';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

@Component({
    selector: 'app-relatorioCheckupsPeriodoComponent',
    templateUrl: './relatorio-checkups-periodo.component.html',
    styleUrls: ['./relatorio-checkups-periodo.component.scss']
})
export class CheckupsPeriodoComponent implements OnInit {
    public relatorioCheckupPeriodoList: Array<any> = [];
    public formCadastro: FormGroup;
    public listaAvaliadores: Array<any> = [];
    public loading = false
    public nenhumRegistro = true
    

    constructor(private relatorioCadastroService: RelatorioCadastroService, 
        private professorService: ProfessorService, private toastr: ToastrService

    ) {
    }

    ngOnInit() {
        this.formCadastro = new FormGroup({
            AvaliadorID: new FormControl(0, []),
            PeriodoDe: new FormControl('', []),
            PeriodoAte: new FormControl('', []),
        });

        this.atualizarListaAvaliadores()
    }

    atualizarListagemRelatorio() {
        const classeContexto = this

        if (this.formCadastro.value.PeriodoDe === '' || this.formCadastro.value.PeriodoAte === '') {
            this.toastr.error('Preencha o período de e até para realizar a busca.', 'Erro')
            this.loading = false
            return
        }
        this.loading = true

        if (this.formCadastro.value.PeriodoDe > this.formCadastro.value.PeriodoAte) {
            this.toastr.error('A data de início do período não pode ser maior que a data de término.', 'Erro')
            this.loading = false
            return
        }
        if (this.formCadastro.value.AvaliadorID === null  || this.formCadastro.value.AvaliadorID === 0 || this.formCadastro.value.AvaliadorID === undefined) {
           //seta como ''
            this.formCadastro.value.AvaliadorID = 0
        }

 
        this.relatorioCadastroService.getRelatorioCheckupsPeriodo(this.formCadastro.value).subscribe({
            next(response) {
                classeContexto.relatorioCheckupPeriodoList = response as Array<any>
                classeContexto.nenhumRegistro = classeContexto.relatorioCheckupPeriodoList.length === 0
                classeContexto.loading = false
                
                setTimeout(() => {
                    classeContexto.scrollToElementById('listaPacientes')
                }, 100)
            }, error() {
                classeContexto.loading = false
            }
        })
    }

    atualizarListaAvaliadores() {
        const classeContexto = this
        this.professorService.getObterListaAvaliadores().subscribe({
            next(response) {
                classeContexto.listaAvaliadores = response as Array<any>
            }, error() {
            }
        })
    }

    baixarPDF() {
        const doc = new jsPDF();

        const avaliadorSelecionado = this.listaAvaliadores.find(avaliador => avaliador.ID === this.formCadastro.value.AvaliadorID)?.Nome || 'Todos';
        const periodoDeRaw = this.formCadastro?.value?.PeriodoDe;
        const periodoAteRaw = this.formCadastro?.value?.PeriodoAte;
        
        const periodoDe = isValid(new Date(periodoDeRaw))
            ? this.dateToDDMMYYYY(addDays(new Date(periodoDeRaw), 1))
            : 'Não especificado';
        
        const periodoAte = isValid(new Date(periodoAteRaw))
            ? this.dateToDDMMYYYY(addDays(new Date(periodoAteRaw), 1))
            : 'Não especificado';

        const totalCheckups = this.relatorioCheckupPeriodoList?.length;
        // Adicionando cabeçalho
        doc.setFontSize(14);
        doc.text('Relatório de CHECK-UPS SAFE® por Período', 14, 20);
        doc.setFontSize(12);
        doc.text(`Avaliador: ${avaliadorSelecionado}`, 14, 30);
        doc.text(`Período: ${periodoDe} - ${periodoAte}`, 14, 40);
        doc.text(`Total: ${totalCheckups}`, 14, 50);

        // Adicionando tabela
        (doc as any).autoTable({
            head: [['#', 'Avaliador', 'Cliente', 'Cadastro', 'Início', 'Término','Teste físico']],
            body: this.relatorioCheckupPeriodoList.map((relatorio, index) => [
                index + 1,
                relatorio.Avaliador,
                relatorio.Cliente,
                this.dateToDDMMYYYY(relatorio.Cadastro),
                this.dateToDDMMYYYY(relatorio.InicioCheckup),
                this.dateToDDMMYYYY(relatorio.EnvioCheckup),
                this.dateToDDMMYYYY(relatorio.Avaliacao)
            ]),
            startY: 60,
        });

        // Baixar o PDF
        doc.save(`relatorio_checkup_periodo_${new Date().toISOString().slice(0, 10)}.pdf`);
    }

    baixarExcel() {
        // Prepare os dados para exportação
        const data = this.relatorioCheckupPeriodoList.map((relatorio, index) => ({
            '#': index + 1,
            'Avaliador': relatorio.Avaliador,
            'Cliente': relatorio.Cliente,
            'Cadastro': this.dateToDDMMYYYY(relatorio.Cadastro),
            'Início': this.dateToDDMMYYYY(relatorio.InicioCheckup),
            'Término': this.dateToDDMMYYYY(relatorio.EnvioCheckup),
            'Teste físico': this.dateToDDMMYYYY(relatorio.Avaliacao)
        }));

        // Crie uma nova planilha
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);

        // Crie um novo livro de trabalho
        const workbook: XLSX.WorkBook = {
            Sheets: { 'Relatório de Check-up': worksheet },
            SheetNames: ['Relatório de Check-up']
        };

        // Converta o livro de trabalho para um arquivo binário
        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

        // Baixar o arquivo
        this.saveAsExcelFile(excelBuffer, `relatorio_checkup_periodo_${new Date().toISOString().slice(0, 10)}`);
    }
    private EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    private EXCEL_EXTENSION = '.xlsx';

    private saveAsExcelFile(buffer: any, fileName: string): void {
        const data: Blob = new Blob([buffer], { type: this.EXCEL_TYPE });
        saveAs(data, fileName + this.EXCEL_EXTENSION);
    }

    // Definições de tipo e extensão do Excel

    baixarLaudo(id: number) {
        window.open(`https://api.plataformasafe.com.br/home/DownloadLaudoCompleto/${id}`, "_blank")
      }

    scrollToElementById(id: string) {
        const element = document.getElementById(id)
        element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
      }

    dateToDDMMYYYY(date: Date) {
        if (!date) return date
        date = new Date(date)
        return date.toLocaleDateString()
    }

    getGenero(idGenero: number) {
        switch (idGenero) {
            case 1:
                return 'Masculino'
            case 2:
                return 'Feminino'
            default:
                return ''
        }
    }

}
