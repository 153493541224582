import {DateTime} from 'luxon';
import {Component} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {AnamneseService} from '@services/dashboard/anamnese/anamnese.service';
import {Router} from "@angular/router";

@Component({
    selector: 'app-anamneses', templateUrl: './anamneses.component.html', styleUrls: ['./anamneses.component.scss']
})
export class AnamnesesComponent {

    public dataPesquisa: any = {
        Paciente: "",
        Grupo: "",
        Avaliador: "",
        Cupom: "",
        Evento: "",
        PeriodoDe: "",
        PeriodoAte: "",
        Z: "",
        Status: "",
        Preenchida: null,
        Avaliada: null,
    };
    public listaAnamneses: any;
    public nenhumaAnamneseEncontrada: boolean = true;
    public anamnesesSelecionadas: number[] = [];
    public loadingListaAnamneses: boolean = false;
    public avaliarLoading: boolean = false;
    public loadingDevolverAnamnese: boolean = false;
    public pacienteIdSelecionado: number;
    public opcoesPreenchida = [{value: '', descricao: 'Selecione...'}, {
        value: 'true', descricao: 'Sim'
    }, {value: 'false', descricao: 'Não'}];
    public opcoesStatus = [{value: '', descricao: 'Selecione...'}, {value: '1', descricao: 'Em Branco'}, {
        value: '2', descricao: 'Incompleto'
    }, {value: '3', descricao: 'Completo'}];

    constructor(private anamneseService: AnamneseService, private toastr: ToastrService, private router: Router) {
    }

    ngOnInit(): void {
        // Código de inicialização, se necessário
    }


    getListaAnamneses(): void {
        this.loadingListaAnamneses = true;
        this.anamnesesSelecionadas = [];
        this.anamneseService.postAnamneseConsultar(this.dataPesquisa).subscribe({
            next: (response) => {
                this.listaAnamneses = response;
                this.nenhumaAnamneseEncontrada = this.listaAnamneses.length === 0;
                this.loadingListaAnamneses = false;
                setTimeout(() => {
                    this.scrollToElementById('listaPacientes');
                    }, 1000);
            }, error: (error) => {
                if (error?.error?.Message) {
                    this.toastr.error(error.error.Message);
                } else {
                    this.toastr.error('Erro ao buscar anamneses, contate o suporte')
                }

                this.loadingListaAnamneses = false;
            }
        });
    }

    selectAll(event: any): void {
        const checkboxes = document.querySelectorAll('input[type="checkbox"]');
        checkboxes.forEach((checkbox: any) => {
            checkbox.checked = event.target.checked;
        });
        this.anamnesesSelecionadas = event.target.checked ? this.listaAnamneses.map(item => item.ID) : [];
    }

    selecionarPaciente(pacienteId: number): void {
        const anamnese = this.listaAnamneses?.find(a => a.IdentificadorHash == pacienteId)
        const index = this.anamnesesSelecionadas.indexOf(pacienteId);

        if (index === -1) {
            this.pacienteIdSelecionado = anamnese?.ID
            this.anamnesesSelecionadas.push(pacienteId);
        } else {
            this.pacienteIdSelecionado = undefined
            this.anamnesesSelecionadas.splice(index, 1);
        }
    }

    scrollToElementById(id: string) {
     
        const element = document.getElementById(id)
        element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    
      }

    handleDevolverAnamnese(): void {
        if (this.anamnesesSelecionadas.length === 0) {
            this.toastr.warning('Selecione ao menos um paciente para devolver a anamnese');
            return;
        }

        let body = {
            //@ts-ignore
            Hash: this.anamnesesSelecionadas[0]?.IdentificadorHash
        }
        this.loadingDevolverAnamnese = true;

        this.anamneseService.putDevolverAnamnese(body).subscribe({
            next: (response) => {
                this.toastr.success('Anamnese devolvida com sucesso');
                this.getListaAnamneses();
                this.loadingDevolverAnamnese = false;
            }, error: (error) => {
                if (error?.error?.Message) {
                    this.toastr.error(error.error.Message);
                } else {
                    this.toastr.error('Erro ao devolver anamnese, contate o suporte')
                }
                this.loadingDevolverAnamnese = false;
            }
        });
    }


    onAvaliar() {
        this.avaliarLoading = true;
        if (this.anamnesesSelecionadas.length === 0) {
            this.toastr.warning('Selecione ao menos um paciente para avaliar a anamnese');
            return;
        }
        // @ts-ignore
        const Hash: any = this.anamnesesSelecionadas[0]?.IdentificadorHash;
        this.anamneseService.getObterLinkAvaliacao(Hash).subscribe({
            next: (response: any) => {
                this.avaliarLoading = false;
                const urlParts = response.split('/'); 
                const hash = urlParts[urlParts.length - 1]; 

                this.router.navigate(['/dashboard/acesso-avaliador/usuarios-avaliacoes/alterar/', hash]);
   
            }, error: (error) => {
                this.avaliarLoading = false;
                if (error?.error?.Message) {
                    this.toastr.error(error.error.Message);
                } else {
                    this.toastr.error('Erro ao obter link de avaliação, contate o suporte')
                }
            }
        });
        
    }

    handleLaudo(context: string): void {

        if (!this.anamnesesSelecionadas.length) {
            this.toastr.warning('Selecione ao menos um paciente para baixar o laudo');
            return;
        }
        if (context === 'incompleto') {
            //@ts-ignore
            window.open(`https://api.plataformasafe.com.br/home/DownloadLaudoSemDadosPessoais/${this.anamnesesSelecionadas[0]?.IdentificadorHash}`, "_blank")
            return
        }
         //@ts-ignore
        window.open(`https://api.plataformasafe.com.br/home/DownloadLaudoCompleto/${this.anamnesesSelecionadas[0]?.IdentificadorHash}`, "_blank")
    }

    handleVisualizarPacienteAnamnese(): void {
         //@ts-ignore
        this.router.navigate([`/dashboard/acesso-avaliador/anamneses/visualizar/${this.anamnesesSelecionadas[0]?.IdentificadorHash}`]);

    }

    handleVisualizarAnamnese(pacienteId: number): void {
        window.open(`https://api.plataformasafe.com.br/home/DownloadLaudoCompleto/${pacienteId}`, "_blank")

   }

    formatDate(date) {
        return DateTime.fromISO(date).toFormat('dd/MM/yyyy');
    }

}
