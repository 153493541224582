<div class="d-flex flex-column h-100">
    <div class="d-flex justify-content-between align-items-center">
        <h2 style="margin-left: 10px;">
            Biomarcadores SAFE®
        </h2>
        <a [href]="urlLaudo" target="_blank" *ngIf="urlLaudo" class="form-bnt-salvar btn">
            Baixar Laudo
        </a>
    </div>
    <div class="biomarcadores-container p-3">
        <button class="nav-btn prev-btn" (click)="scrollPrevious()">←</button>
        <div class="biomarcadores-container" (wheel)="onWheel($event)" #list>
            <div class="biomarcadores-inner">
                <app-biomarcador-item *ngFor="let item of biomarcadores" [item]="item" [desativar]="desativarBiomarcadores"></app-biomarcador-item>
            </div>
        </div>
        <button class="nav-btn next-btn" (click)="scrollNext()">→</button>
    </div>
</div>
