import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AppService } from "@services/app.service";
import { AnamneseService } from "@services/dashboard/anamnese/anamnese.service";
import { CheckupClienteService } from "@services/dashboard/checkup-cliente/checkup-cliente.service";
import { DadosCadastraisService } from "@services/dashboard/dados-cadastrais/dados-cadastrais.service";
import { Subscription } from "rxjs";

@Component({
    selector: 'app-dashboard-aluno',
    templateUrl: './dashboard-aluno.component.html',
    styleUrls: ['./dashboard-aluno.component.scss']
})
export class DashboardAlunoComponent implements OnInit, OnDestroy {
    @Input() usuarioId: number | string = ""
    @Input() public isAlunoAvaliador: boolean = false
    public desativarCamposAnamnese: boolean = false
    public loadingAnamnese: boolean = false;
    public tipoZ: number = 0
    public imcAluno: string = "Sem informação"
    public idadeBiologicaAlunoCardio: string = "Sem informação"
    public idadeBiologicaAlunoForca: string = ""
    public loadingIsAlunoAvaliador: boolean = false
    prescricaoCandidato = {
        nivel: "", recomendacao: ""
    }
    prescricoesEstiloDeVida = {
        1: {
            nivel: "Muito abaixo do desejável",
            recomendacao: "A partir dos resultados, recomendamos, que neste momento da sua vida, tome algumas atitudes para melhorar a qualidade do seu Estilo de Vida e, consequentemente da sua Saúde Funcional."
        }, 2: {
            nivel: "Abaixo do desejável",
            recomendacao: "Recomendamos que Você tome algumas atitudes para melhorar os biomarcadores SAFE destacados nos resultados e para que Você possa ter um estilo de vida mais saudável."
        }, 3: {
            nivel: "Regular",
            recomendacao: "Recomendamos que Você dê prioridade aos biomacadores SAFE que foram avaliados abaixo do ideal para melhorar o seu Estilo de Vida."
        }, 4: {
            nivel: "Bom",
            recomendacao: "Você está próximo do Z5. Recomendamos apenas que tenha um pouco mais de foco na melhora dos biomacadores SAFE que precisam ser melhorados."
        }, 5: {
            nivel: "Excelente",
            recomendacao: "Parabéns! Mantenha-se no Patamar Z5 e não se esqueça de estimular outras pessoas do seu ciclo social a ter a mesma atitude em relação à qualidade da saúde."
        },
    }
    private checkupSubscription: Subscription;
    private anamneseSubscription: Subscription
    private loadingAnamnseSubscription: Subscription;

    constructor(private appService: AppService, private checkupClienteService: CheckupClienteService, public dadosCadastraisService: DadosCadastraisService, public anamneseService: AnamneseService) {
    }

    ngOnInit(): void {
        const classeContexto = this
        if (this.isAlunoAvaliador) {
            this.loadingIsAlunoAvaliador = true
        }

        // caso não seja passado o parametro usuarioId, o usuário id padrão será o do usuário logado
        this.usuarioId = this.usuarioId ? this.usuarioId : this.appService.getDadosUsuarioLogadoStorage()?.ID

        this.dadosCadastraisService.getDadosCadastrais(this.usuarioId)
        this.dadosCadastraisService.getPerfis()
        this.checkupClienteService.getCheckupCliente(this.usuarioId)

        // escuta a mudanca de estado do checkup
        this.checkupSubscription = this.checkupClienteService.checkup$.subscribe((novoValor) => {
            if (novoValor?.HashAnamnese) {
                this.anamneseService.getAnamneseRequest(novoValor?.HashAnamnese)
                this.anamneseService.retornarResultadoAnamnese(novoValor?.HashAnamnese).subscribe({
                    next(response) {
                        // @ts-ignore
                        const imcDados = response.find(item => item?.BiomarcadorID == 29);
                        // @ts-ignore
                        const idadeBiologicaDadosCardio = response.find(item => item?.BiomarcadorID == 55);
                        // @ts-ignore
                        const idadeBiologicaDadosForca = response.find(item => item?.BiomarcadorID == 54);


                        // ts-ignore
                        classeContexto.imcAluno = imcDados?.ValorAlcancado || "Sem informação"
                        let spanDescricaoImcMenuSidebar = document.getElementById("span-descricao-imc-menu-sidebar")
                        if (spanDescricaoImcMenuSidebar) {
                            spanDescricaoImcMenuSidebar.innerHTML = imcDados?.ValorAlcancado ? `${imcDados?.ValorAlcancado}KG/M²` : "Sem informação"
                        }

                        // cardio
                        // ts-ignore
                        const idadeBioCardio = idadeBiologicaDadosCardio?.ValorAlcancado ? JSON.parse(idadeBiologicaDadosCardio?.ValorAlcancado) : {}
                        // ts-ignore
                        classeContexto.idadeBiologicaAlunoCardio = idadeBioCardio?.idadeBiologicaAtual || 'Sem informação'

                        // força
                        // ts-ignore
                        // const idadeBioForca = idadeBiologicaDadosCardio?.ValorAlcancado ? JSON.parse(idadeBiologicaDadosForca?.ValorAlcancado) : {}
                        // ts-ignore
                        classeContexto.idadeBiologicaAlunoForca = idadeBiologicaDadosForca?.ValorAlcancado || 'Sem informação'


                    }, error() {

                    }
                })
            }
        });

        this.anamneseSubscription = this.anamneseService.ananmese$.subscribe(novoValor => {
            if (novoValor?.Z) {
                this.tipoZ = novoValor?.Z
                this.prescricaoCandidato = this.prescricoesEstiloDeVida[novoValor?.Z]
            }
            if (novoValor?.Z == 0) {
                this.tipoZ = 0
                this.prescricaoCandidato = {
                    nivel: "", recomendacao: "Sem informação"
                }
            }
        })

        // listening loading anamnese
        this.loadingAnamnseSubscription = this.anamneseService.loadingAnamnse$.subscribe(novoValor => {
            this.loadingAnamnese = novoValor
        })

        this.loadingIsAlunoAvaliador = false

    }

    ngOnDestroy(): void {
        // Certifique-se de cancelar a inscrição para evitar vazamentos de memória
        this.checkupSubscription.unsubscribe();
        this.loadingAnamnseSubscription.unsubscribe()
        this.anamneseSubscription.unsubscribe()
        this.isAlunoAvaliador = false
        this.usuarioId = ""
    }
} 
