import {Component, OnInit} from '@angular/core';
import {AppService} from '@services/app.service';
// import { ModalLimAspectosFisicosComponent } from '../Modal2-InfoSobreSaude/modal-limAspectosFisicos.component';
import {ModalDadosPessoaisComponent} from '../Modal2-DadosPessoais/modal-dadospessoais.component';
import {FormControl, FormGroup} from '@angular/forms';
import {AnamneseService} from '@services/dashboard/anamnese/anamnese.service';
import {
    ModalQuestFarmPrelimComponent
} from "@components/dashboard/modais/Modal4-QuestFarmPrelim/modal-questFarmPrelim.component";
import {Router} from "@angular/router";

@Component({
    selector: 'app-modal-infoSobreSaude',
    templateUrl: './modal-infoSobreSaude.component.html',
    styleUrls: ['./modal-infoSobreSaude.component.scss']
})
export class ModalInfoSobreSaudeComponent implements OnInit {
    public erroAvancarAnamnese
    public formGroupEtapa3: FormGroup
    public carregandoAtualizarAnamnese: boolean = false
    public desativarCampos: boolean = false


    constructor(
        private appService: AppService,
        private anamneseService: AnamneseService,
        private router: Router
    ) {
    }

    ngOnInit(): void {
        this.desativarCampos = this.router.url.includes('acesso-avaliador')
        const anamnese = this.anamneseService.getAnamnese()

        this.formGroupEtapa3 = new FormGroup({
            NumeroEtapa: new FormControl(3),
            AnamneseID: new FormControl({value: anamnese?.Etapa3?.AnamneseID, disabled: this.desativarCampos}),
            DadosMedico: new FormControl({value: anamnese?.Etapa3?.DadosMedico, disabled: this.desativarCampos}),
            HipArtSist: new FormControl({value: anamnese?.Etapa3?.HipArtSist, disabled: this.desativarCampos}),
            HipArtSistMeses: new FormControl({
                value: anamnese?.Etapa3?.HipArtSistMeses,
                disabled: this.desativarCampos
            }),
            Fibromialgia: new FormControl({value: anamnese?.Etapa3?.Fibromialgia, disabled: this.desativarCampos}),
            FibromialgiaMeses: new FormControl({
                value: anamnese?.Etapa3?.FibromialgiaMeses,
                disabled: this.desativarCampos
            }),
            Lupos: new FormControl({value: anamnese?.Etapa3?.Lupos, disabled: this.desativarCampos}),
            LuposMeses: new FormControl({value: anamnese?.Etapa3?.LuposMeses, disabled: this.desativarCampos}),
            Hipotireoidismo: new FormControl({
                value: anamnese?.Etapa3?.Hipotireoidismo,
                disabled: this.desativarCampos
            }),
            HipotireoidismoMeses: new FormControl({
                value: anamnese?.Etapa3?.HipotireoidismoMeses,
                disabled: this.desativarCampos
            }),
            Diabetes: new FormControl({value: anamnese?.Etapa3?.Diabetes, disabled: this.desativarCampos}),
            DiabetesMeses: new FormControl({value: anamnese?.Etapa3?.DiabetesMeses, disabled: this.desativarCampos}),
            SindromeSjogren: new FormControl({
                value: anamnese?.Etapa3?.SindromeSjogren,
                disabled: this.desativarCampos
            }),
            SindromeSjogrenMeses: new FormControl({
                value: anamnese?.Etapa3?.SindromeSjogrenMeses,
                disabled: this.desativarCampos
            }),
            NefriteLupica: new FormControl({value: anamnese?.Etapa3?.NefriteLupica, disabled: this.desativarCampos}),
            NefriteLupicaMeses: new FormControl({
                value: anamnese?.Etapa3?.NefriteLupicaMeses,
                disabled: this.desativarCampos
            }),
            Dislipidemia: new FormControl({value: anamnese?.Etapa3?.Dislipidemia, disabled: this.desativarCampos}),
            DislipidemiaMeses: new FormControl({
                value: anamnese?.Etapa3?.DislipidemiaMeses,
                disabled: this.desativarCampos
            }),
            ArtriteReumatoide: new FormControl({
                value: anamnese?.Etapa3?.ArtriteReumatoide,
                disabled: this.desativarCampos
            }),
            ArtriteReumatoideMeses: new FormControl({
                value: anamnese?.Etapa3?.ArtriteReumatoideMeses,
                disabled: this.desativarCampos
            }),
            Osteoartrite: new FormControl({value: anamnese?.Etapa3?.Osteoartrite, disabled: this.desativarCampos}),
            OsteoartriteMeses: new FormControl({
                value: anamnese?.Etapa3?.OsteoartriteMeses,
                disabled: this.desativarCampos
            }),
            Osteoporose: new FormControl({value: anamnese?.Etapa3?.Osteoporose, disabled: this.desativarCampos}),
            OsteoporoseMeses: new FormControl({
                value: anamnese?.Etapa3?.OsteoporoseMeses,
                disabled: this.desativarCampos
            }),
            Neoplasia: new FormControl({value: anamnese?.Etapa3?.Neoplasia, disabled: this.desativarCampos}),
            NeoplasiaMeses: new FormControl({value: anamnese?.Etapa3?.NeoplasiaMeses, disabled: this.desativarCampos}),
            Osteopenia: new FormControl({value: anamnese?.Etapa3?.Osteopenia, disabled: this.desativarCampos}),
            OsteopeniaMeses: new FormControl({
                value: anamnese?.Etapa3?.OsteopeniaMeses,
                disabled: this.desativarCampos
            }),
            NeoplasiaPreExistente: new FormControl({
                value: anamnese?.Etapa3?.NeoplasiaPreExistente,
                disabled: this.desativarCampos
            }),
            NeoplasiaPreExistenteMeses: new FormControl({
                value: anamnese?.Etapa3?.NeoplasiaPreExistenteMeses,
                disabled: this.desativarCampos
            }),
            Depressao: new FormControl({value: anamnese?.Etapa3?.Depressao, disabled: this.desativarCampos}),
            DepressaoMeses: new FormControl({value: anamnese?.Etapa3?.DepressaoMeses, disabled: this.desativarCampos}),
            TranstornoDeAnsiedade: new FormControl({
                value: anamnese?.Etapa3?.TranstornoDeAnsiedade,
                disabled: this.desativarCampos
            }),
            TranstornoDeAnsiedadeMeses: new FormControl({
                value: anamnese?.Etapa3?.TranstornoDeAnsiedadeMeses,
                disabled: this.desativarCampos
            }),
            CriseDePanico: new FormControl({value: anamnese?.Etapa3?.CriseDePanico, disabled: this.desativarCampos}),
            CriseDePanicoMeses: new FormControl({
                value: anamnese?.Etapa3?.CriseDePanicoMeses,
                disabled: this.desativarCampos
            }),
            Anorexia: new FormControl({value: anamnese?.Etapa3?.Anorexia, disabled: this.desativarCampos}),
            AnorexiaMeses: new FormControl({value: anamnese?.Etapa3?.AnorexiaMeses, disabled: this.desativarCampos}),
            Bulimia: new FormControl({value: anamnese?.Etapa3?.Bulimia, disabled: this.desativarCampos}),
            BulimiaMeses: new FormControl({value: anamnese?.Etapa3?.BulimiaMeses, disabled: this.desativarCampos}),
            Alcoolismo: new FormControl({value: anamnese?.Etapa3?.Alcoolismo, disabled: this.desativarCampos}),
            AlcoolismoMeses: new FormControl({
                value: anamnese?.Etapa3?.AlcoolismoMeses,
                disabled: this.desativarCampos
            }),
            LocalStOsteoartrite: new FormControl({
                value: anamnese?.Etapa3?.LocalStOsteoartrite,
                disabled: this.desativarCampos
            }),
            OutraEnfermidade: new FormControl({
                value: anamnese?.Etapa3?.OutraEnfermidade,
                disabled: this.desativarCampos
            }),
            HistQuedas: new FormControl({value: anamnese?.Etapa3?.HistQuedas, disabled: this.desativarCampos}),
            DtEnfermidadesPreExistentes: new FormControl({
                value: anamnese?.Etapa3?.DtEnfermidadesPreExistentes,
                disabled: this.desativarCampos
            }),
            Covid19: new FormControl({value: anamnese?.Etapa3?.Covid19, disabled: this.desativarCampos}),
            DataCovid19: new FormControl({
                value: anamnese?.Etapa3?.DataCovid19 ? this.formataDataParaAmericana(anamnese?.Etapa3?.DataCovid19) : null,
                disabled: this.desativarCampos
            }),

            DataInicio: new FormControl({value: new Date(), disabled: this.desativarCampos}),
            DataFim: new FormControl({value: null, disabled: true})
        })
        //escuta mudanças no formulário e aceita apenas valores numéricos nos campos de meses
        this.formGroupEtapa3.get('HipArtSistMeses').valueChanges.subscribe((value) => {
            this.formGroupEtapa3.get('HipArtSistMeses').setValue(value.replace(/\D/g, ''), {emitEvent: false})
        });
        this.formGroupEtapa3.get('FibromialgiaMeses').valueChanges.subscribe((value) => {
            this.formGroupEtapa3.get('FibromialgiaMeses').setValue(value.replace(/\D/g, ''), {emitEvent: false})
        });
        this.formGroupEtapa3.get('LuposMeses').valueChanges.subscribe((value) => {
            this.formGroupEtapa3.get('LuposMeses').setValue(value.replace(/\D/g, ''), {emitEvent: false})
        });
        this.formGroupEtapa3.get('HipotireoidismoMeses').valueChanges.subscribe((value) => {
            this.formGroupEtapa3.get('HipotireoidismoMeses').setValue(value.replace(/\D/g, ''), {emitEvent: false})
        });
        this.formGroupEtapa3.get('DiabetesMeses').valueChanges.subscribe((value) => {
            this.formGroupEtapa3.get('DiabetesMeses').setValue(value.replace(/\D/g, ''), {emitEvent: false})
        });
        this.formGroupEtapa3.get('SindromeSjogrenMeses').valueChanges.subscribe((value) => {
            this.formGroupEtapa3.get('SindromeSjogrenMeses').setValue(value.replace(/\D/g, ''), {emitEvent: false})
        });
        this.formGroupEtapa3.get('NefriteLupicaMeses').valueChanges.subscribe((value) => {
            this.formGroupEtapa3.get('NefriteLupicaMeses').setValue(value.replace(/\D/g, ''), {emitEvent: false})
        });
        this.formGroupEtapa3.get('DislipidemiaMeses').valueChanges.subscribe((value) => {
            this.formGroupEtapa3.get('DislipidemiaMeses').setValue(value.replace(/\D/g, ''), {emitEvent: false})
        });
        this.formGroupEtapa3.get('ArtriteReumatoideMeses').valueChanges.subscribe((value) => {
            this.formGroupEtapa3.get('ArtriteReumatoideMeses').setValue(value.replace(/\D/g, ''), {emitEvent: false})
        });
        this.formGroupEtapa3.get('OsteoartriteMeses').valueChanges.subscribe((value) => {
            this.formGroupEtapa3.get('OsteoartriteMeses').setValue(value.replace(/\D/g, ''), {emitEvent: false})
        });
        this.formGroupEtapa3.get('OsteoporoseMeses').valueChanges.subscribe((value) => {
            this.formGroupEtapa3.get('OsteoporoseMeses').setValue(value.replace(/\D/g, ''), {emitEvent: false})
        });
        this.formGroupEtapa3.get('NeoplasiaMeses').valueChanges.subscribe((value) => {
            this.formGroupEtapa3.get('NeoplasiaMeses').setValue(value.replace(/\D/g, ''), {emitEvent: false})
        });
        this.formGroupEtapa3.get('OsteopeniaMeses').valueChanges.subscribe((value) => {
            this.formGroupEtapa3.get('OsteopeniaMeses').setValue(value.replace(/\D/g, ''), {emitEvent: false})
        });
        this.formGroupEtapa3.get('NeoplasiaPreExistenteMeses').valueChanges.subscribe((value) => {
            this.formGroupEtapa3.get('NeoplasiaPreExistenteMeses').setValue(value.replace(/\D/g, ''), {emitEvent: false})
        });
        this.formGroupEtapa3.get('DepressaoMeses').valueChanges.subscribe((value) => {
            this.formGroupEtapa3.get('DepressaoMeses').setValue(value.replace(/\D/g, ''), {emitEvent: false})
        });
        this.formGroupEtapa3.get('TranstornoDeAnsiedadeMeses').valueChanges.subscribe((value) => {
            this.formGroupEtapa3.get('TranstornoDeAnsiedadeMeses').setValue(value.replace(/\D/g, ''), {emitEvent: false})
        });
        this.formGroupEtapa3.get('CriseDePanicoMeses').valueChanges.subscribe((value) => {
            this.formGroupEtapa3.get('CriseDePanicoMeses').setValue(value.replace(/\D/g, ''), {emitEvent: false})
        });
        this.formGroupEtapa3.get('AnorexiaMeses').valueChanges.subscribe((value) => {
            this.formGroupEtapa3.get('AnorexiaMeses').setValue(value.replace(/\D/g, ''), {emitEvent: false})
        });
        this.formGroupEtapa3.get('BulimiaMeses').valueChanges.subscribe((value) => {
            this.formGroupEtapa3.get('BulimiaMeses').setValue(value.replace(/\D/g, ''), {emitEvent: false})
        });
        this.formGroupEtapa3.get('AlcoolismoMeses').valueChanges.subscribe((value) => {
            this.formGroupEtapa3.get('AlcoolismoMeses').setValue(value.replace(/\D/g, ''), {emitEvent: false})
        });

    }

    handleClickFecharModal() {
        this.appService.closeModal()
    }

    handleClickVoltarModal() {
        this.appService.closeModal()
        const modalRef = this.appService.openModal(ModalDadosPessoaisComponent, {size: 'lg', backdrop: false})
    }

    formataDataParaAmericana(data) {
        //recebe 2024-04-12T00:00:00
        // retorna 2024-04-12
        return data.split('T')[0]
    }

    handleClickProximoModal() {
        // caso os campos esteja desativados,
        // significa que é o professor acessando a tela
        // E ele só pode visualizar as informacoes
        // Logo, a gente nao atualiza a anamnese, apenas
        // Redireciona para a nova tela
        if (this.desativarCampos) {
            this.appService.closeModal()
            this.appService.openModal(ModalQuestFarmPrelimComponent)
            return
        }

        const anamnese = this.anamneseService.getAnamnese()
        const classeContexto = this

        this.carregandoAtualizarAnamnese = true

        const novaAnamneseAtualizada = {
            ...anamnese,
            Etapa3: {
                ...anamnese?.Etapa3,
                ...this.formGroupEtapa3.getRawValue(),
                DataFim: new Date(),
            }
        }

        // primeiro, validamos a anamnese, para ver se os campos obrigatórios foram todos preenchidos
        // Caso estejam preenchidos, deixamos avançar
        this.anamneseService.validarAnamnese(novaAnamneseAtualizada).subscribe({
            next(response) {
                // verifico se há etapas inválidas (endpoint retorna 200 mesmo pra dados inválidos)
                // @ts-ignore
                if (typeof response?.etapaInvalida?.mensagem == 'string' && response?.etapaInvalida?.etapa === 3) {
                    // @ts-ignore
                    classeContexto.erroAvancarAnamnese = response?.etapaInvalida?.mensagem
                    classeContexto.carregandoAtualizarAnamnese = false
                    return
                }

                // atualiza a anamnese se tudo estiver ok
                classeContexto.anamneseService.putAnamnese(novaAnamneseAtualizada).subscribe({
                    next(response2) {

                        classeContexto.carregandoAtualizarAnamnese = false
                        classeContexto.anamneseService.setAnamnese(response2)
                        classeContexto.appService.closeModal()
                        classeContexto.appService.openModal(ModalQuestFarmPrelimComponent)
                    },
                    error(response) {
                        classeContexto.carregandoAtualizarAnamnese = false
                        if (typeof response?.error?.Message == 'string') {
                            classeContexto.erroAvancarAnamnese = response?.error?.Message
                        } else {
                            classeContexto.erroAvancarAnamnese = "Ocorreu um erro ao atualizar a anamnese"
                        }
                    }
                })

            },
            error(erro) {
                classeContexto.carregandoAtualizarAnamnese = false
                if (typeof erro?.Message == 'string') {
                    classeContexto.erroAvancarAnamnese = erro?.Message
                } else {
                    classeContexto.erroAvancarAnamnese = "Ocorreu um erro ao validar as informações preenchidas"
                }
            }
        })

    }
}
