import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AcessoAvaliadorGuard } from "@guards/acesso-avaliador.guard";
import { AuthGuard } from '@guards/auth.guard';
import { DynamicRouteGuard } from '@guards/dynamic-route.guard';
import { NonAuthGuard } from '@guards/non-auth.guard';
import { ContainerMainAlunoComponent } from "@modules/main/container/container-main-aluno/container-main-aluno.component";
import { ContainerMainAvaliadorComponent } from "@modules/main/container/container-main-avaliador/container-main-avaliador.component";
import { checkupsAluno } from '@pages/aluno/checkups-aluno/checkups-aluno';
import { DashboardAlunoComponent } from "@pages/aluno/dashboard-aluno/dashboard-aluno.component";
import { MeusDados } from '@pages/aluno/meus-dados/meus-dados';
import { redefinirSenhaAluno } from '@pages/aluno/redefinir-senha-aluno/redefinir-senha-aluno';
import { AnamnesesVisualizar } from '@pages/anamneses-visualizar/anamneses-visualizar';
import { DashboardAvaliadorComponent } from "@pages/avaliador/dashboard-avaliador/dashboard-avaliador.component";
import { AniversariantesComponent } from '@pages/avaliador/relatorios/relatorio-aniversariantes/relatorio-aniversariantes.component';
import { RelatorioAutoConscienciaComponent } from "@pages/avaliador/relatorios/relatorio-auto-consciencia/relatorio-auto-consciencia.component";
import { RelatorioBiomarcadoresSafeComponent } from "@pages/avaliador/relatorios/relatorio-biomarcadores-safe/relatorio-biomarcadores-safe.component";
import { CheckupsEvolucaoComponent } from '@pages/avaliador/relatorios/relatorio-checkups-evolucao/relatorio-checkups-evolucao.component';
import { CheckupsPeriodoComponent } from '@pages/avaliador/relatorios/relatorio-checkups-periodo/relatorio-checkups-periodo.component';
import { RelatorioPreCadastroComponent } from "@pages/avaliador/relatorios/relatorio-pre-cadastro/relatorio-pre-cadastro.component";
import { RelatorioTabelaGorduraComponent } from "@pages/avaliador/relatorios/relatorio-tabela-gordura/relatorio-tabela-gordura.component";
import { AnamnesesComponent } from '@pages/cadastros/anamneses/anamneses/anamneses.component';
import { CheckupsAdicionar } from '@pages/cadastros/checkups/checkups-adicionar/checkups-adicionar';
import { CheckupsComponent } from '@pages/cadastros/checkups/checkups/checkups.component';
import { AdicionarCupomComponent } from "@pages/cadastros/cupons/adicionar-cupom/adicionar-cupom.component";
import { CuponsComponent } from "@pages/cadastros/cupons/cupons.component";
import { EditarCupomComponent } from '@pages/cadastros/cupons/editar-cupom/editar-cupom.component';
import { DadosAlunoComponent } from "@pages/cadastros/dados-aluno/dados-aluno/dados-aluno.component";
import { MeusClientesComponent } from '@pages/cadastros/meus-clientes/meus-clientes/meus-clientes.component';
import { SenhasProvisoriasComponent } from '@pages/cadastros/senhas-provisorias/senhas-provisorias/senhas-provisorias.component';
import { duvidasComponent } from '@pages/duvidas/duvidas.component';
import { ebooksComponent } from '@pages/ebooks/ebooks.component';
import { LoginPageComponent } from '@pages/login-page/login-page.component';
import { RecuperarSenhaPageComponent } from '@pages/recuperar-senha-page/recuperar-senha-page.component';
import { RedefinirSenhaComponent } from '@pages/redefinir-senha/redefinir-senha.component';
import { RegisterPageCupomComponent } from '@pages/register-page-cupom/register-page-cupom.component';
import { RegisterPageComponent } from '@pages/register-page/register-page.component';
import { TokenPageComponent } from '@pages/token-page/token-page.component';
import { UsuariosAlterar } from '@pages/usuarios-alterar/usuarios-alterar';
import { UsuariosAvaliacoesAlterar } from '@pages/usuarios-avaliacoes-alterar/usuarios-avaliacoes-alterar';
import { UsuariosAvaliacoesComponent } from '@pages/usuarios-avaliacoes/usuarios-avaliacoes.component';
import { UsuariosIncluir } from '@pages/usuarios-incluir/usuarios-incluir';
import { UsuariosComponent } from '@pages/usuarios/usuarios.component';

const routes: Routes = [
  {
    path: '',
    component: LoginPageComponent
  },
  {
    path: 'dashboard/acesso-avaliador',
    component: ContainerMainAvaliadorComponent,
    canActivate: [AuthGuard, AcessoAvaliadorGuard],
    canActivateChild: [AuthGuard, AcessoAvaliadorGuard],
    children: [
      {
        path: '',
        component: DashboardAvaliadorComponent,
      },
      {
        path: 'dados-aluno/:alunoId',
        component: DadosAlunoComponent,
      },
      {
        path: 'usuarios',
        component: UsuariosComponent,
      },
      {
        path: 'usuarios-avaliacoes',
        component: UsuariosAvaliacoesComponent,
      },
      {
        path: 'anamneses',
        component: AnamnesesComponent,
      },
      {
        path: 'senhas-provisorias',
        component: SenhasProvisoriasComponent,
      },
      {
        path: 'checkups',
        component: CheckupsComponent,
      },
      {
        path: 'checkups/adicionar',
        component: CheckupsAdicionar,
      },
      {
        path: 'checkups/alterar/:checkupId',
        component: CheckupsAdicionar,
      },
      {
        path: 'meus-clientes',
        component: MeusClientesComponent,
      },
      {
        path: 'tabela-gordura',
        component: RelatorioTabelaGorduraComponent
      },
      {
        path: 'usuarios-avaliacoes/alterar/:usuarioId',
        component: UsuariosAvaliacoesAlterar,
      },
      {
        path: 'relatorios/biomarcadores-safe',
        component: RelatorioBiomarcadoresSafeComponent,
      },
      {
        path: 'relatorios/pre-cadastro',
        component: RelatorioPreCadastroComponent,
      },
      {
        path: 'relatorios/auto-consciencia',
        component: RelatorioAutoConscienciaComponent,
      },
      {
        path: 'relatorios/relatorio-checkup-por-periodo',
        component: CheckupsPeriodoComponent,
      },
      {
        path: 'relatorios/relatorio-evolucao',
        component: CheckupsEvolucaoComponent,
      },
      {
        path: 'relatorios/relatorio-aniversariantes-mes',
        component: AniversariantesComponent,
      },
      {
        path: 'usuarios/incluir',
        component: UsuariosIncluir,
      },
      {
        path: 'usuarios/alterar/:usuarioId',
        component: UsuariosAlterar,
      },
      {
        path: 'cupons',
        component: CuponsComponent,
      },
      {
        path: 'ebooks',
        component: ebooksComponent,
      },
      {
          path: 'cupons/adicionar',
          component: AdicionarCupomComponent,
      },
      {
          path: 'cupons/alterar/:cupomId',
          component: EditarCupomComponent,
      },
      {
          path: 'anamneses/visualizar/:hash',
          component: AnamnesesVisualizar,
      },
      {
        path: 'duvidas',
        component: duvidasComponent,
      },
    ]
  },
  {
    path: 'dashboard/acesso-aluno',
    component: ContainerMainAlunoComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: '',
        component: DashboardAlunoComponent
      },
      {
        path: 'meus-dados',
        component: MeusDados
      },
      {
        path: 'meus-checkups',
        component: checkupsAluno
      },
      {
        path: 'alterar-senha-aluno',
        component: redefinirSenhaAluno
      },
    ]
  },
  {
    path: 'login',
    component: LoginPageComponent,
    canActivate: [NonAuthGuard]
  },
  {
    path: 'token',
    component: TokenPageComponent,
    canActivate: [NonAuthGuard]
  },
  {
    path: 'cadastro/:hash',
    component: RegisterPageComponent,
  },
  {
    path: 'cadastro',
    component: RegisterPageComponent,
  },
  {
    path: 'cupom/promocao/:hash',
    component: RegisterPageCupomComponent,
  },
  {
    path: 'recuperar-senha',
    component: RecuperarSenhaPageComponent
  },
  {
    path: 'redefinir-senha',
    component: RedefinirSenhaComponent,
  },
  {
    path: 'home/index',
    canActivate: [DynamicRouteGuard],
    data: {
        expectedParams: ['nomeUsuario', 'hash'], 
    }
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
