<div class="modal-biomarcador">
    <div class="modal-backdrop"></div>

    <header class="bg-light-blue modal-biomarcador-header d-flex">
        <div class="d-flex justify-content-center align-items-center" style="flex: 1;">
            <div>
                <div>
                    <span class="modal-biomarcador-header-safe">Biomarcadores SAFE®</span>
                </div>
                <div>
                    <span class="text-yellow modal-biomarcador-header-tipo-biomarcador">
                        Percepção saúde
                    </span>
                </div>
            </div>
            <div style="padding-left: 20px;">
                <img alt="" src="assets/svg/biomarcador-1.svg">
            </div>
        </div>

        <div class="d-flex justify-content-end align-items-center " style="flex: 1;">
            <div class="identificador-pagina">
                <span class="text-yellow">6/</span>
                <span class="text-light">10</span>
            </div>
        </div>


        <div (click)="handleClickFecharModal()" class="fechar-modal">
            <img alt="" src="assets/svg/close-icon.svg">
        </div>
    </header>
    <div class="modal-bio-form content bg-light p-4">
        <h1 class="form-title">Preencha os Dados</h1>
        <div class="row vertical-content">
            <form [formGroup]="formGroupEtapa6" class="form-form p-3">
                <div class="col-md-12 row">
                    <div class="col-12 mb-4">
                        <span>Essa etapa é muito interessante, preste atenção nas repostas, pois sempre com o valor 0
                            sendo considerado o Melhor resultado e o valor 10 sendo considerado o Pior resultado.
                        </span>
                        <span class="form-label-texts mt-3"><br/> De acordo com a escala visual analógica (EVA) favor
                            pontuar de 0 a 10 o que sentiu em média na última semana nos parâmetros abaixo. </span>
                    </div>
                    <div class="col-12 mt-4">
                        <div>
                            <span class="form-label-texts">Dor: {{ formGroupEtapa6.get('Dor').value }}</span>
                        </div>
                        <div class="mt-2">
                            <div>
                                <img alt="" class="imagem-slider" src="../../../../../assets/img/range-slider.png">
                                <p-slider [max]="10" [min]="0" [step]="1" formControlName="Dor"
                                          styleClass="
                                               {{ formGroupEtapa6.get('Dor').value >=8 ? 'p-slider-vermelho' : '' }}
                                                {{ formGroupEtapa6.get('Dor').value < 8 && formGroupEtapa6.get('Dor').value >=4 ? 'p-slider-amarelo' : '' }}
                                                {{ formGroupEtapa6.get('Dor').value <=3  ? 'p-slider-verde' : '' }}


    "></p-slider>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mt-4">
                        <div *ngIf="formGroupEtapa6.get('Dor').value > 0">

                            <span class="form-label-texts">Citar local(is) onde sente dor:</span>
                            <input class="form-control form-control-sm" formControlName="LocalDor"
                                   placeholder="Ex: Cabeça, pescoço, ombro, etc..." type="text">
                        </div>
                    </div>
                    <div class="col-12 mt-4">
                        <div>
                            <span class="form-label-texts">Tensão/ansiedade: {{ formGroupEtapa6.get('Ansiedade').value
                                }}</span>
                        </div>

                        <div class="mt-2">
                            <div>
                                <img alt="" class="imagem-slider" src="../../../../../assets/img/range-slider.png">
                                <p-slider [max]="10" [min]="0" [step]="1" formControlName="Ansiedade"
                                          styleClass="
                                                {{ formGroupEtapa6.get('Ansiedade').value >=8 ? 'p-slider-vermelho' : '' }}
                                                {{ formGroupEtapa6.get('Ansiedade').value < 8 && formGroupEtapa6.get('Ansiedade').value >=4 ? 'p-slider-amarelo' : '' }}
                                                {{ formGroupEtapa6.get('Ansiedade').value <=3  ? 'p-slider-verde' : '' }}"
                                ></p-slider>
                            </div>
                        </div>

                    </div>
                    <div class="col-12 mt-4">
                        <div>
                            <span class="form-label-texts">Fadiga: {{ formGroupEtapa6.get('Fadiga').value }}</span>
                        </div>

                        <div class="mt-2">
                            <div>
                                <img alt="" class="imagem-slider" src="../../../../../assets/img/range-slider.png">
                                <p-slider [max]="10" [min]="0" [step]="1" formControlName="Fadiga"
                                          styleClass="
                                                {{ formGroupEtapa6.get('Fadiga').value >=8 ? 'p-slider-vermelho' : '' }}
                                                {{ formGroupEtapa6.get('Fadiga').value < 8 && formGroupEtapa6.get('Fadiga').value >=4 ? 'p-slider-amarelo' : '' }}
                                                {{ formGroupEtapa6.get('Fadiga').value <=3  ? 'p-slider-verde' : '' }}"
                                ></p-slider>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mt-4">
                        <div>
                            <span class="form-label-texts">Gratidão (Qual o seu nível de gratidão em relação a sua vida,
                                família, trabalho, saúde e relacionamentos): {{ formGroupEtapa6.get('Gratidao').value
                                }}</span>
                        </div>

                        <div class="mt-2">
                            <div>
                                <img alt="" class="imagem-slider" src="../../../../../assets/img/range-slider.png">
                                <p-slider [max]="10" [min]="0" [step]="1" formControlName="Gratidao"
                                          styleClass="
                                                {{ formGroupEtapa6.get('Gratidao').value >=8 ? 'p-slider-vermelho' : '' }}
                                                {{ formGroupEtapa6.get('Gratidao').value < 8 && formGroupEtapa6.get('Gratidao').value >=4 ? 'p-slider-amarelo' : '' }}
                                                {{ formGroupEtapa6.get('Gratidao').value <=3  ? 'p-slider-verde' : '' }}"
                                ></p-slider>
                            </div>
                        </div>

                    </div>
                    <div class="col-12 mt-4">
                        <div>
                            <span class="form-label-texts">Ânimo: {{ formGroupEtapa6.get('Animo').value }}</span>
                        </div>

                        <div class="mt-2">
                            <div>
                                <img alt="" class="imagem-slider" src="../../../../../assets/img/range-slider.png">
                                <p-slider [max]="10" [min]="0" [step]="1" formControlName="Animo"
                                          styleClass="
                                                {{ formGroupEtapa6.get('Animo').value >=8 ? 'p-slider-vermelho' : '' }}
                                                {{ formGroupEtapa6.get('Animo').value < 8 && formGroupEtapa6.get('Animo').value >=4 ? 'p-slider-amarelo' : '' }}
                                                {{ formGroupEtapa6.get('Animo').value <=3  ? 'p-slider-verde' : '' }}"
                                ></p-slider>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mt-4">
                        <div>
                            <span class="form-label-texts">Qualidade do sono: {{ formGroupEtapa6.get('Sono').value
                                }}</span>
                        </div>

                        <div class="mt-2">
                            <div>
                                <img alt="" class="imagem-slider" src="../../../../../assets/img/range-slider.png">
                                <p-slider [max]="10" [min]="0" [step]="1" formControlName="Sono"
                                          styleClass="
                                                {{ formGroupEtapa6.get('Sono').value >=8 ? 'p-slider-vermelho' : '' }}
                                                {{ formGroupEtapa6.get('Sono').value < 8 && formGroupEtapa6.get('Sono').value >=4 ? 'p-slider-amarelo' : '' }}
                                                {{ formGroupEtapa6.get('Sono').value <=3  ? 'p-slider-verde' : '' }}"
                                ></p-slider>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mt-4">
                        <div>
                            <span class="form-label-texts">Disposição ao acordar: {{
                                formGroupEtapa6.get('Disposicao').value }}</span>
                        </div>

                        <div class="mt-2">
                            <div>
                                <img alt="" class="imagem-slider" src="../../../../../assets/img/range-slider.png">
                                <p-slider [max]="10" [min]="0" [step]="1" formControlName="Disposicao"
                                          styleClass="
                                                {{ formGroupEtapa6.get('Disposicao').value >=8 ? 'p-slider-vermelho' : '' }}
                                                {{ formGroupEtapa6.get('Disposicao').value < 8 && formGroupEtapa6.get('Disposicao').value >=4 ? 'p-slider-amarelo' : '' }}
                                                {{ formGroupEtapa6.get('Disposicao').value <=3  ? 'p-slider-verde' : '' }}"
                                ></p-slider>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mt-4">
                        <div>
                            <span class="form-label-texts">Percepção de saúde geral: {{
                                formGroupEtapa6.get('Percepcao').value }}</span>
                        </div>

                        <div class="mt-2">
                            <div>
                                <img alt="" class="imagem-slider" src="../../../../../assets/img/range-slider.png">
                                <p-slider [max]="10" [min]="0" [step]="1" formControlName="Percepcao"
                                          styleClass="
                                                {{ formGroupEtapa6.get('Percepcao').value >=8 ? 'p-slider-vermelho' : '' }}
                                                {{ formGroupEtapa6.get('Percepcao').value < 8 && formGroupEtapa6.get('Percepcao').value >=4 ? 'p-slider-amarelo' : '' }}
                                                {{ formGroupEtapa6.get('Percepcao').value <=3  ? 'p-slider-verde' : '' }}"
                                ></p-slider>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mt-4">
                        <div>
                            <span class="form-label-texts">Relato da capacidade funcional (atividades do dia a dia): {{
                                formGroupEtapa6.get('CapacidadeFuncional').value }}</span>
                        </div>

                        <div class="mt-2">
                            <div>
                                <img alt="" class="imagem-slider" src="../../../../../assets/img/range-slider.png">
                                <p-slider [max]="10" [min]="0" [step]="1" formControlName="CapacidadeFuncional"
                                          styleClass="
                                                {{ formGroupEtapa6.get('CapacidadeFuncional').value >=8 ? 'p-slider-vermelho' : '' }}
                                                {{ formGroupEtapa6.get('CapacidadeFuncional').value < 8 && formGroupEtapa6.get('CapacidadeFuncional').value >=4 ? 'p-slider-amarelo' : '' }}
                                                {{ formGroupEtapa6.get('CapacidadeFuncional').value <=3  ? 'p-slider-verde' : '' }}"
                                ></p-slider>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 mt-4">
                        <div>
                            <span class="form-label-texts">Nível de estresse: {{
                                formGroupEtapa6.get('Estresse').value }}</span>
                        </div>


                        <div class="mt-2">
                            <div>
                                <img alt="" class="imagem-slider" src="../../../../../assets/img/range-slider.png">
                                <p-slider [max]="10" [min]="0" [step]="1" formControlName="Estresse"
                                          styleClass="
                                                {{ formGroupEtapa6.get('Estresse').value >=8 ? 'p-slider-vermelho' : '' }}
                                                {{ formGroupEtapa6.get('Estresse').value < 8 && formGroupEtapa6.get('Estresse').value >=4 ? 'p-slider-amarelo' : '' }}
                                                {{ formGroupEtapa6.get('Estresse').value <=3  ? 'p-slider-verde' : '' }}"
                                ></p-slider>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mt-4">
                        <div>
                            <span class="form-label-texts">Percepção de felicidade: {{
                                formGroupEtapa6.get('Felicidade').value }}</span>
                        </div>


                        <div class="mt-2">
                            <div>
                                <img alt="" class="imagem-slider" src="../../../../../assets/img/range-slider.png">
                                <p-slider [max]="10" [min]="0" [step]="1" formControlName="Felicidade"
                                          styleClass="
                                                {{ formGroupEtapa6.get('Felicidade').value >=8 ? 'p-slider-vermelho' : '' }}
                                                {{ formGroupEtapa6.get('Felicidade').value < 8 && formGroupEtapa6.get('Felicidade').value >=4 ? 'p-slider-amarelo' : '' }}
                                                {{ formGroupEtapa6.get('Felicidade').value <=3  ? 'p-slider-verde' : '' }}"
                                ></p-slider>
                            </div>
                        </div>
                    </div>


                    <div *ngIf="erroAvancarAnamnese" class="col-12 mb-2 mt-4">
                        <div class="alert alert-danger" role="alert">
                            {{ erroAvancarAnamnese }}
                        </div>
                    </div>

                    <div class="col-12 form-group-buttons row" style="margin-left: 20px;">
                        <div class="col-md-3 mb-3 ">
                            <button (click)="handleClickFecharModal()"
                                    class="btn btn-secondary btn-block btn-lg form-bnt-voltar">Fechar
                            </button>
                        </div>
                        <div class="col-md-3 mb-3 ">
                            <button (click)="handleClickVoltarModal()"
                                    class="btn btn-secondary btn-block btn-lg form-bnt-voltar">Voltar
                            </button>
                        </div>

                        <div *ngIf="!carregandoAtualizarAnamnese" class="col-md-6 mb-3">
                            <button (click)="handleClickProximoModal()"
                                    class="btn btn-primary btn-block btn-sm form-bnt-salvar">Próximo
                            </button>
                        </div>

                        <div *ngIf="carregandoAtualizarAnamnese" class="col-md-6 mb-3">
                            <button (click)="handleClickProximoModal()"
                                    class="btn btn-primary btn-block btn-sm form-bnt-salvar" disabled>Carregando
                            </button>
                        </div>
                    </div>
                    
                </div>
            </form>
        </div>
    </div>
</div>
