import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AppService } from '@services/app.service';
import { AnamneseService } from '@services/dashboard/anamnese/anamnese.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-modal-bioConfirmarEnvio',
  templateUrl: './modal-bioConfirmarEnvio.component.html',
  styleUrls: ['./modal-bioConfirmarEnvio.component.scss'],
})
export class ModalBioConfirmarEnvioComponent implements OnInit {

  carregandoConfirmarAnamnese: boolean = false
  sucessoConfirmarAnamnese: boolean = false
  urlLaudo: any = ""

  constructor(
    private appService: AppService,
    private anamneseService: AnamneseService,
    private toastr: ToastrService,

  ) {
  }

  ngOnInit(): void {

  }

  handleClickFecharModal() {
    this.appService.closeModal()
  }

  handleClickConfirmarModal() {
    const classeContexto = this
    const anamnese = this.anamneseService.getAnamnese()

    classeContexto.carregandoConfirmarAnamnese = true
    classeContexto.sucessoConfirmarAnamnese = false

    classeContexto.anamneseService.finalizarAnamnese().subscribe({
      next() {
        classeContexto.carregandoConfirmarAnamnese = false
        classeContexto.sucessoConfirmarAnamnese = true


        classeContexto.toastr.success("Sucesso", "Anamnese enviada com sucesso")
        classeContexto.urlLaudo = `https://api.plataformasafe.com.br/home/DownloadLaudoCompleto/${anamnese?.IdentificadorHash}`
        // window.open(`https://api.plataformasafe.com.br/home/DownloadLaudoCompleto/${anamnese?.IdentificadorHash}`, "_blank")
      },
      error(error) {
        classeContexto.toastr.error("Erro", "Não foi possível enviar a anamnese, tente novamente")
      }
    })
  }
}
