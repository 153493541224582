<!-- Brand Logo -->

<div>
    <!-- Aluno MENU -->
    <div class="menu-button-wrapper d-md-none" [ngClass]="{ 'd-none': isMenuOpen }">
        <button class="btn btn-primary menu-button-style" (click)="toggleMenu()">
            <span *ngIf="!isMenuOpen" aria-hidden="true">&#9776;</span> <!-- Ícone hamburger -->
        </button>
    </div>


    <!-- mostra esse se for 2, se n eh 1 -->
    <div class="menu-sidebar-style" [ngClass]="{
        'show-menu': isMenuOpen,
        'bg-azul-claro-plataforma-safe': isAvaliador,
        'bg-white': isAvaliador === false,
        'menu-sidebar-style': true
    }">

        <button class="d-md-none btn btn-primary menu-button-style" (click)="toggleMenu()">
            <span *ngIf="isMenuOpen" aria-hidden="true">&#10006;</span> <!-- Ícone X -->
        </button>


        <!-- Sidebar -->
        <div class="sidebar bg-transparent p-0 m-0" 
         style="max-height: 100vh; overflow-y: auto;"
        >
            <!-- Sidebar user (optional) -->
            <div class="user-panel mt-3 pb-3 mb-3 d-flex flex-column bg-transparent">
                <div class="image d-flex justify-content-center align-itens-center p-0">
                    <img
                        src="{{  isAvaliador ? 'assets/img/default-profile-professor.png' : 'assets/img/default-profile.png' }}"
                        class="img-circle img-circle-border" alt="User Image" width="150" height="150">
                </div>

                <div class="px-1">
                    <div class="divider"></div>

                    <div class="px-4">

                        <span class="nome-usuario-logado" [ngClass]="{
                            'text-amarelo-plataforma-safe': isAvaliador,
                            'text-azul-claro-plataforma-safe': isAvaliador === false
                        }">
                            {{ pegarPrimeiroNomeESegundoNomeDaString(user?.Nome) }}
                        </span>

                        <div class=" my-3" *ngIf="isAvaliador === false">
                        </div>

                        <div class="info-perfil" *ngIf="isAvaliador === false">
                            <span class="info-perfil-titulo">
                                Classificação de saúde:
                            </span>
                            <span class="info-perfil-descricao">
                                <!-- Z2 -->
                                {{ tipoZ }}
                            </span>
                        </div>

                        <div class="info-perfil" *ngIf="isAvaliador === false">
                            <span class="info-perfil-titulo">
                                Idade:
                            </span>
                            <span class="info-perfil-descricao">
                                <!-- 48 anos -->
                                <!-- Sem informação -->
                                {{ dadosCadastraisService.dadosCadastrais.Idade ?
                                dadosCadastraisService.dadosCadastrais.Idade + ' anos' : 'Sem informação'}}
                            </span>
                        </div>

                        <div class="info-perfil" *ngIf="isAvaliador === false">
                            <span class="info-perfil-titulo">
                                Peso:
                            </span>
                            <span class="info-perfil-descricao">
                                {{ anamnese?.Etapa2?.Peso ? anamnese?.Etapa2?.Peso + ' kg' : 'Sem informação'}}
                            </span>
                        </div>

                        <div class="info-perfil" *ngIf="isAvaliador === false">
                            <span class="info-perfil-titulo">
                                IMC:
                            </span>
                            <span class="info-perfil-descricao span-descricao-imc-menu-sidebar" id="span-descricao-imc-menu-sidebar">
                                Sem informação
                            </span>
                        </div>
                    </div>

                </div>
            </div>

            <!-- Sidebar Menu -->
            <nav style="overflow-y: hidden; width: 100%;">
                <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                    data-accordion="false">
                    <app-menu-item *ngFor="let item of menu" [menuItem]="item"
                    (click)="toggleMenuMobile()"
                                   [textColor]="isAvaliador ? 'text-white' : 'text-gray'"></app-menu-item>
                </ul>
            </nav>


            <div class="sidebar-footer mt-5 d-flex flex-column justify-content-center align-items-center">
                <span class="titulo" [ngClass]="{
                    'text-white': isAvaliador,
                    'text-gray': isAvaliador === false
                }">
                    Equipe SAFE®
                </span>
                <span class="versao" [ngClass]="{
                    'text-white': isAvaliador,
                    'text-gray': isAvaliador === false
                }">
                    <!-- versão 1.0.0 -->
                </span>
            </div>
        </div>

    </div>
    <!-- Aluno MENU FIM -->
</div>
