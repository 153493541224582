<div class="screen-container" style="overflow-y: scroll; max-height: 100vh;">

    <div class="container-fluid bg-transparent">
 

        <div>
            <div class="w-100 d-flex justify-content-between mt-5">
                <h5 class="home-dash mt-5">
                    Avaliações
                </h5>
                <img alt="plat-safe-logo" height="122px" src="assets/img/logoSafeTextoBranco.png" width="154px">
            </div>
            <div class="divider-dash"></div>
        </div>

        <div class="container-tabela">
            <h6 class="titulo-tabela-meus-clientes text-azul-claro-plataforma-safe mb-3">
                Filtrar Avaliações
            </h6>

            <div class="col-md-12 mt-6">
                <div class="row">
                    <div class="col-md-2 col-sm-12 mt-2">
                        <span class="text-azul-claro-plataforma-safe">Cliente</span>
                        <input  [(ngModel)]="dataPesquisa.Cliente" name="Cliente" id="Cliente" type="input"
                            class="form-input-box form-control">
                    </div>
                    <div class="col-md-2 col-sm-10 mt-2">
                        <span class="text-azul-claro-plataforma-safe">Situação</span>
                            <select [(ngModel)]="dataPesquisa.Situacao" class="form-control form-input-select" id="Situacao"
                                placeholder="Selecione">
                            <option value="" disabled>Selecione</option>
                            <option *ngFor="let situacao of situacaoPesquisa" [ngValue]="situacao.value">
                                {{ situacao.descricao }}
                            </option>
                        </select>
                    </div>
                    <div class="col-sm-12 col-md-3 mt-2">
                        <span class="text-azul-claro-plataforma-safe">Avaliador</span>
                        <input name="Avaliador" id="Avaliador" type="input" [(ngModel)]="dataPesquisa.Avaliador"
                        name="Avaliador" 
                            class="form-input-box form-control">
                    </div>

                    <div class="col-md-3 col-sm-12 mt-2 d-flex align-items-center justify-content-center ">
                        <button class="button-usuarios btn btn-secondary btn-block mt-4 " type="button"
                            (click)="getListaPacientes()"
                        [disabled]="loading"
                        >
                           {{
                            loading ? 'Carregando...' : 'Pesquisar'
                           }}
                        </button>
                    </div>
                </div>
            </div>

        </div>

        <div class="mt-5">
            <h6 class="titulo-usuarios text-cinza-claro-plataforma-safe">
                Ações
            </h6>
            <span class="text-help text-cinza-claro-plataforma-safe">Para ativar alguma ação, selecione algum paciente.</span>
            <div class="col-12 mt-6">
                <div class="row">
                    <div class="col-sm-6 col-md-3 mt-2">
                        <button class="button-usuarios btn btn-secondary btn-block mt-4" type="button"
                            [disabled]="!pacientesSelecionados.length || pacientesSelecionados.length > 1"
                            (click)="visualizarAvaliacao()"
                        >
                            Preencher Avaliação
                        </button>
                    </div>
                    <div class="col-sm-6 col-md-3 mt-2">
                        <button class="button-usuarios btn btn-secondary btn-block mt-4" type="button"
                        [disabled]="!pacientesSelecionados.length || pacientesSelecionados.length > 1 || loadingDevolverAvaliacao"
                        (click)="devolverAvaliacao()">
                             {{ loadingDevolverAvaliacao ? 'Aguarde...' : 'Devolver Avaliação' }}
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="container-tabela mt-5" style="max-height: 600px; overflow-y: auto;">

            <h2 class="titulo-tabela-meus-clientes text-azul-claro-plataforma-safe">
                Avaliações Cadastradas
            </h2>

            <table class="table table-striped">
                <thead>
                    <tr>
                        <th class="th-tabela text-azul-claro-plataforma-safe">
                            <input type="checkbox" class="form-checkbox-input" id="selectAllCheckbox"
                                (change)="selectAll($event)">
                        </th>
                        <th class="th-tabela text-azul-claro-plataforma-safe">Cliente</th>
                        <th class="th-tabela text-azul-claro-plataforma-safe">Avaliador</th>
                        <th class="th-tabela text-azul-claro-plataforma-safe">Situação</th>
                        <th class="th-tabela text-azul-claro-plataforma-safe">Data de Cadastro</th>
                        <th class="th-tabela text-azul-claro-plataforma-safe">Data de Atualização</th>
                        <th class="th-tabela text-azul-claro-plataforma-safe">Data de Finalização</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="nenhumUsuarioEncontrado && !loading">
                        <td colspan="8" class="text-center">Nenhum usuário encontrado, por favor insira um filtro.</td>
                    </tr>
                    <ng-container *ngIf="!nenhumUsuarioEncontrado && !loading">
                        <tr *ngFor="let item of listaPacientes">
                            <td><input type="checkbox" class="form-checkbox-input" [checked]="pacientesSelecionados.includes(item.id)" (change)="selecionarPaciente(item.IdentificadorHash)"></td>
                            <td>{{ item.NomeCliente }}</td>
                            <td>{{ item.NomeAvaliador }}</td>
                            <td>{{ item.Situacao === 1 ? 'Pendente' : item.Situacao === 2 ? 'Finalizada' : 'Cancelado' }}</td>
                            <td>{{ formatDate(item.DataInclusao) }}</td>
                            <td>{{ formatDate(item.DataAlteracao) }}</td>
                            <td>{{ formatDate(item.DataFinalizacao) }}</td>
                        </tr>
                    </ng-container>
                </tbody>

            </table>
            <div style="flex: 1;" class="d-flex justify-content-center align-items-center" *ngIf="loading">
                <div class="spinner-border text-azul-plataforma-safe" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>
        <div id="listaPacientes" #target></div>

    </div>
</div>
