import {Component, OnDestroy, OnInit} from '@angular/core';
import {CheckupClienteService} from '@services/dashboard/checkup-cliente/checkup-cliente.service';
import Chart from 'chart.js';
import {Subscription} from "rxjs";

@Component({
    selector: 'app-dados-teia', templateUrl: './dados-teia.component.html', styleUrls: ['./dados-teia.component.scss']
})
export class DadosTeiaComponent implements OnInit, OnDestroy {

    public chartRadar: any
    public checkupSubscription: Subscription

    constructor(private checkupClienteService: CheckupClienteService) {

    }

    ngOnInit(): void {
        this.checkupSubscription = this.checkupClienteService.checkup$.subscribe(checkupAtualizado => {
            if (checkupAtualizado?.DadosTeia) {
                this.createChartRadar(checkupAtualizado?.DadosTeia)
            }
        })

    }

    ngOnDestroy() {
        this.checkupSubscription.unsubscribe()
    }

    createChartRadar(dadosTeia: any = {}) {
        const data = {
            labels: ['Dor', 'Felicidade', 'Gratidao', 'Percepcao', 'Sono', 'Fadiga', 'Ansiedade', 'Estresse', 'Capacidade Funcional', 'Animo', 'Disposicao',],
            datasets: [{
                label: 'Teia SAFE',
                data: [dadosTeia?.Dor || 0, dadosTeia?.Felicidade || 0, dadosTeia?.Gratidao || 0, dadosTeia?.Percepcao || 0, dadosTeia?.Sono || 0, dadosTeia?.Fadiga || 0, dadosTeia?.Ansiedade || 0, dadosTeia?.Estresse || 0, dadosTeia?.CapacidadeFuncional || 0, dadosTeia?.Animo || 0, dadosTeia?.Disposicao || 0,],
                backgroundColor: 'rgba(0, 91, 133, 0.2)',
                borderColor: '#005B85',
                pointBackgroundColor: '#005B85',
                pointBorderColor: '#fff',
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: '#005B85'
            }]
        };

        const config = {
            type: 'radar', data: data, options: {
                scale: {
                    ticks: {
                        // changes here
                        beginAtZero: true, min: 0, max: 10
                    }
                }, elements: {
                    line: {
                        borderWidth: 3
                    }
                }, responsive: true, maintainAspectRatio: false, plugins: {
                    title: {
                        display: true, text: 'Teia SAFE', font: {
                            size: 18, weight: 'bold'
                        }, color: '#005B85'
                    }
                },
            }
        }
        this.chartRadar = new Chart("ChartRadar", config)
        this.chartRadar.update()
    }
}
