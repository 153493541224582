<div class="modal-biomarcador">
    <div class="modal-backdrop"></div>

    <header class="bg-light-blue modal-biomarcador-header d-flex">
        <div style="flex: 1;" class="d-flex justify-content-center align-items-center">
            <div>
                <div>
                    <span class="modal-biomarcador-header-safe">Biomarcadores SAFE®</span>
                </div>
                <div>
                    <span class="text-yellow modal-biomarcador-header-tipo-biomarcador">
                        CheckList 2
                    </span>
                </div>
            </div>
            <div style="padding-left: 20px;">
                <img src="assets/svg/biomarcador-1.svg" alt="">
            </div>
        </div>

        <div style="flex: 1;" class="d-flex justify-content-end align-items-center ">
            <div class="identificador-pagina">
                <span class="text-yellow">8/</span>
                <span class="text-light">10</span>
            </div>
        </div>


        <div class="fechar-modal" (click)="handleClickFecharModal()">
            <img src="assets/svg/close-icon.svg" alt="">
        </div>
    </header>
    <div class="modal-bio-form content bg-light p-4">
        <h1 class="form-title">Preencha os Dados</h1>
        <h6 class="form-title2"><br />Beba uma água e vamos continuar!</h6>
        <div class="row vertical-content">
            <form class="form-form p-3" [formGroup]="formGroupEtapa8">
                <div class="col-md-12 row">
                    <div class="col-12">
                        <span>
                            Instruções: Esta pesquisa nos dá informações sobre a sua saúde. As informações fornecidas
                            nos mostrarão como você se sente e o quão bem você é capaz de fazer suas atividades da vida
                            diária. Responda cada questão marcando a resposta como indicado. Caso você esteja inseguro
                            em como responder, por favor, tente responder o melhor que puder.
                        </span>
                    </div>

                    <!-- SAUDE -->
                    <div class="col-md-6 mt-3">
                        <span class="form-label-texts"> Em geral, você diria que sua saúde é: </span>
                        <div class="mt-2 d-flex align-items-center">
                            <input formControlName="Saude" type="radio" [value]="1" class="form-input-checkbox"
                                [checked]="formGroupEtapa8.get('Saude').value == 1" />
                            <label class="form-label-texts-helper-check">Excelente</label>
                        </div>
                        <div class="d-flex align-items-center">
                            <input formControlName="Saude" type="radio" [value]="2" class="form-input-checkbox"
                                [checked]="formGroupEtapa8.get('Saude').value == 2" />
                            <label class="form-label-texts-helper-check">Muito Boa</label>
                        </div>
                        <div class="d-flex align-items-center">
                            <input formControlName="Saude" type="radio" [value]="3" class="form-input-checkbox"
                                [checked]="formGroupEtapa8.get('Saude').value == 3" />
                            <label class="form-label-texts-helper-check">Boa</label>
                        </div>
                        <div class="d-flex align-items-center">
                            <input formControlName="Saude" type="radio" [value]="4" class="form-input-checkbox"
                                [checked]="formGroupEtapa8.get('Saude').value == 4" />
                            <label class="form-label-texts-helper-check">Ruim</label>
                        </div>
                        <div class="d-flex align-items-center">
                            <input formControlName="Saude" type="radio" class="form-input-checkbox" [value]="5"
                                [checked]="formGroupEtapa8.get('Saude').value == 5" />
                            <label class="form-label-texts-helper-check">Muito Ruim</label>
                        </div>
                    </div>
                    <!-- SAUDE -->

                    <!-- SAUDE COMPARADA -->
                    <div class="col-md-6 mt-3">
                        <span class="form-label-texts"> Comparada há um ano atrás, como você classifica sua saúde em
                            geral, agora? </span>
                        <div class="mt-2 d-flex align-items-center">
                            <input formControlName="SaudeComparada" class="form-input-checkbox" type="radio" [value]="1"
                                [checked]="formGroupEtapa8.get('SaudeComparada').value == 1" />
                            <label class="form-label-texts-helper-check">Muito melhor agora do que há um ano
                                atrás</label>
                        </div>

                        <div class="d-flex align-items-center">
                            <input formControlName="SaudeComparada" class="form-input-checkbox" type="radio" [value]="2"
                                [checked]="formGroupEtapa8.get('SaudeComparada').value == 2" />
                            <label class="form-label-texts-helper-check"> Um pouco melhor agora do que há um ano
                                atrás</label>
                        </div>

                        <div class="d-flex align-items-center">
                            <input formControlName="SaudeComparada" class="form-input-checkbox" type="radio" [value]="3"
                                [checked]="formGroupEtapa8.get('SaudeComparada').value == 3" />
                            <label class="form-label-texts-helper-check"> Quase a mesma de um ano atrás</label>
                        </div>

                        <div class="d-flex align-items-center">
                            <input formControlName="SaudeComparada" class="form-input-checkbox" type="radio" [value]="4"
                                [checked]="formGroupEtapa8.get('SaudeComparada').value == 4" />
                            <label class="form-label-texts-helper-check"> Um pouco pior agora do que um ano
                                atrás</label>
                        </div>

                        <div class="d-flex align-items-center">
                            <input formControlName="SaudeComparada" class="form-input-checkbox" type="radio" [value]="5"
                                [checked]="formGroupEtapa8.get('SaudeComparada').value == 5" />
                            <label class="form-label-texts-helper-check"> Muito pior agora do que há um ano
                                atrás</label>
                        </div>
                    </div>
                    <!-- SAUDE COMPARADA -->

                    <div class="col-12 mt-3">
                        <span class="form-label-texts-helper"> Os seguintes itens são sobre atividades que você poderia
                            fazer atualmente durante um dia comum. Devido a sua saúde, você tem dificuldades para fazer
                            essas atividades? Neste caso, quanto? </span>
                    </div>

                    <!-- AtivVigorosas -->
                    <div class="col-md-6 mt-3">
                        <span class="form-label-texts"><br />Atividades vigorosas, que exigem muito esforço, tais como
                            correr, levantar objetos pesados, participar em esportes árduos </span>

                        <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesDificuldade">
                            <input formControlName="AtivVigorosas" class="form-input-checkbox" type="radio"
                                [value]="opcao.value"
                                [checked]="formGroupEtapa8.get('AtivVigorosas').value == opcao.value" />
                            <label class="form-label-texts-helper-check">{{opcao.label }} </label>
                        </div>
                    </div>
                    <!-- AtivVigorosas -->

                    <!-- AtivModeradas -->
                    <div class="col-md-6 mt-3">
                        <span class="form-label-texts"> Atividades moderadas, tais como mover uma mesa, passar aspirador
                            de pó, jogar bola, varrer a casa </span>


                        <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesDificuldade">
                            <input formControlName="AtivModeradas" class="form-input-checkbox" type="radio"
                                [value]="opcao.value"
                                [checked]="formGroupEtapa8.get('AtivModeradas').value == opcao.value" />
                            <label class="form-label-texts-helper-check">{{opcao.label }} </label>
                        </div>
                    </div>
                    <!-- AtivModeradas -->

                    <!-- COMPRAS -->
                    <div class="col-md-6 mt-3">
                        <span class="form-label-texts"> Levantar ou carregar mantimentos (compras) </span>

                        <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesDificuldade">
                            <input formControlName="Compras" class="form-input-checkbox" type="radio"
                                [value]="opcao.value" [checked]="formGroupEtapa8.get('Compras').value == opcao.value" />
                            <label class="form-label-texts-helper-check">{{opcao.label }} </label>
                        </div>
                    </div>
                    <!-- COMPRAS -->

                    <!-- SubirEscadas -->
                    <div class="col-md-6 mt-3">
                        <span class="form-label-texts">Subir vários lances de escada </span>
                        <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesDificuldade">
                            <input formControlName="SubirEscadas" class="form-input-checkbox" type="radio"
                                [value]="opcao.value"
                                [checked]="formGroupEtapa8.get('SubirEscadas').value == opcao.value" />
                            <label class="form-label-texts-helper-check">{{opcao.label }} </label>
                        </div>
                    </div>
                    <!-- SubirEscadas -->

                    <!-- SubirEscada -->
                    <div class="col-md-6 mt-3">
                        <span class="form-label-texts"> Subir um lance de escada </span>

                        <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesDificuldade">
                            <input formControlName="SubirEscada" class="form-input-checkbox" type="radio"
                                [value]="opcao.value"
                                [checked]="formGroupEtapa8.get('SubirEscada').value == opcao.value" />
                            <label class="form-label-texts-helper-check">{{opcao.label }} </label>
                        </div>
                    </div>
                    <!-- SubirEscada -->

                    <!-- Ajoelhar -->
                    <div class="col-md-6 mt-3">
                        <span class="form-label-texts"> Curvar-se, ajoelhar-se ou dobrar-se </span>
                        <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesDificuldade">
                            <input formControlName="Ajoelhar" class="form-input-checkbox" type="radio"
                                [value]="opcao.value"
                                [checked]="formGroupEtapa8.get('Ajoelhar').value == opcao.value" />
                            <label class="form-label-texts-helper-check">{{opcao.label }} </label>
                        </div>
                    </div>
                    <!-- Ajoelhar -->

                    <!-- AndarAcimaUmKm -->
                    <div class="col-md-6 mt-3">
                        <span class="form-label-texts"> Andar mais de 1 quilômetro </span>

                        <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesDificuldade">
                            <input formControlName="AndarAcimaUmKm" class="form-input-checkbox" type="radio"
                                [value]="opcao.value"
                                [checked]="formGroupEtapa8.get('AndarAcimaUmKm').value == opcao.value" />
                            <label class="form-label-texts-helper-check">{{opcao.label }} </label>
                        </div>
                    </div>
                    <!-- AndarAcimaUmKm -->

                    <!-- AndarVariosQuarteiroes -->
                    <div class="col-md-6 mt-3">
                        <span class="form-label-texts"> Andar vários quarteirões (quadras) </span>

                        <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesDificuldade">
                            <input formControlName="AndarVariosQuarteiroes" class="form-input-checkbox" type="radio"
                                [value]="opcao.value"
                                [checked]="formGroupEtapa8.get('AndarVariosQuarteiroes').value == opcao.value" />
                            <label class="form-label-texts-helper-check">{{opcao.label }} </label>
                        </div>
                    </div>
                    <!-- AndarVariosQuarteiroes -->

                    <!-- AndarUmQuarteirao -->
                    <div class="col-md-6 mt-3">
                        <span class="form-label-texts"> Andar um quarteirão (quadras) </span>

                        <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesDificuldade">
                            <input formControlName="AndarUmQuarteirao" class="form-input-checkbox" type="radio"
                                [value]="opcao.value"
                                [checked]="formGroupEtapa8.get('AndarUmQuarteirao').value == opcao.value" />
                            <label class="form-label-texts-helper-check">{{opcao.label }} </label>
                        </div>
                    </div>
                    <!-- AndarUmQuarteirao -->

                    <!-- Banho -->
                    <div class="col-md-6 mt-3">
                        <span class="form-label-texts"> Tomar banho ou vestir-se </span>
                        <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesDificuldade">
                            <input formControlName="Banho" class="form-input-checkbox" type="radio"
                                [value]="opcao.value" [checked]="formGroupEtapa8.get('Banho').value == opcao.value" />
                            <label class="form-label-texts-helper-check">{{ opcao.label }}</label>
                        </div>
                    </div>
                    <!-- Banho -->

                    <div class="col-12 mt-3">
                        <span class="form-label-texts-helper"> Durante as últimas 4 semanas, você teve algum dos
                            seguintes problemas com o seu trabalho ou com alguma atividade diária regular, como
                            consequência de sua saúde física? </span>
                    </div>

                    <!-- DiminuiuTempoAtiv -->
                    <div class="col-md-6 mt-3">
                        <span class="form-label-texts"> Você diminuiu a quantidade de tempo que se dedicava ao seu
                            trabalho ou a outras atividades? </span>
                        <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesSimOuNao">
                            <input formControlName="DiminuiuTempoAtiv" class="form-input-checkbox" type="radio"
                                [value]="opcao.value"
                                [checked]="formGroupEtapa8.get('DiminuiuTempoAtiv').value === opcao.value" />
                            <label class="form-label-texts-helper-check">
                                {{ opcao.label }}
                            </label>
                        </div>

                    </div>
                    <!-- DiminuiuTempoAtiv -->

                    <!-- MenosTarefas -->
                    <div class="col-md-6 mt-3">
                        <span class="form-label-texts"> Realizou menos tarefas do que você gostaria? </span>

                        <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesSimOuNao">
                            <input formControlName="MenosTarefas" class="form-input-checkbox" type="radio"
                                [value]="opcao.value"
                                [checked]="formGroupEtapa8.get('MenosTarefas').value === opcao.value" />
                            <label class="form-label-texts-helper-check">
                                {{ opcao.label }}
                            </label>
                        </div>

                    </div>
                    <!-- MenosTarefas -->

                    <!-- NaoTrabalhou -->
                    <div class="col-md-6 mt-3">
                        <span class="form-label-texts"> Esteve limitado no seu tipo de trabalho ou em outras atividades?
                            (p. ex. necessitou de um esforço extra)? </span>


                        <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesSimOuNao">
                            <input formControlName="NaoTrabalhou" class="form-input-checkbox" type="radio"
                                [value]="opcao.value"
                                [checked]="formGroupEtapa8.get('NaoTrabalhou').value === opcao.value" />
                            <label class="form-label-texts-helper-check">
                                {{ opcao.label }}
                            </label>
                        </div>

                    </div>
                    <!-- NaoTrabalhou -->

                    <!-- DificuldadeTrabalho -->
                    <div class="col-md-6 mt-3">
                        <span class="form-label-texts"> Teve dificuldade de fazer seu trabalho ou outras atividades
                            (exemplo: necessitou de um esforço extra)? </span>

                        <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesSimOuNao">
                            <input formControlName="DificuldadeTrabalho" class="form-input-checkbox" type="radio"
                                [value]="opcao.value"
                                [checked]="formGroupEtapa8.get('DificuldadeTrabalho').value === opcao.value" />
                            <label class="form-label-texts-helper-check">
                                {{ opcao.label }}
                            </label>
                        </div>
                    </div>
                    <!-- DificuldadeTrabalho -->

                    <div class="col-12 mt-3">
                        <span class="form-label-texts-helper"> Durante as últimas 4 semanas, você teve algum dos
                            seguintes problemas com seu trabalho ou outra atividade regular diária, como consequência de
                            algum problema emocional (como sentir-se deprimido ou ansioso)? </span>
                    </div>

                    <!-- QtdTempoTrabalho -->
                    <div class="col-md-6 mt-3">
                        <span class="form-label-texts"> Você diminuiu a quantidade de tempo que se dedicava ao seu
                            trabalho ou a outras atividades? </span>

                        <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesSimOuNao">
                            <input formControlName="QtdTempoTrabalho" class="form-input-checkbox" type="radio"
                                [value]="opcao.value"
                                [checked]="formGroupEtapa8.get('QtdTempoTrabalho').value === opcao.value" />
                            <label class="form-label-texts-helper-check">
                                {{ opcao.label }}
                            </label>
                        </div>
                    </div>
                    <!-- QtdTempoTrabalho -->

                    <!-- MenosTarefasGostaria -->
                    <div class="col-md-6 mt-3">
                        <span class="form-label-texts">Realizou menos tarefa do que você gostaria? </span>

                        <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesSimOuNao">
                            <input formControlName="MenosTarefasGostaria" class="form-input-checkbox" type="radio"
                                [value]="opcao.value"
                                [checked]="formGroupEtapa8.get('MenosTarefasGostaria').value === opcao.value" />
                            <label class="form-label-texts-helper-check">
                                {{ opcao.label }}
                            </label>
                        </div>
                    </div>
                    <!-- MenosTarefasGostaria -->

                    <!-- TarefasCuidado -->
                    <div class="col-md-6 mt-3">
                        <span class="form-label-texts"> Não trabalhou ou não fez qualquer das atividades com tanto
                            cuidado como geralmente faz? </span>
                        <div class="mt-2">
                            <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesSimOuNao">
                                <input formControlName="TarefasCuidado" class="form-input-checkbox" type="radio"
                                    [value]="opcao.value"
                                    [checked]="formGroupEtapa8.get('TarefasCuidado').value === opcao.value" />
                                <label class="form-label-texts-helper-check">
                                    {{ opcao.label }}
                                </label>
                            </div>
                        </div>
                    </div>
                    <!-- TarefasCuidado -->


                    <div class="col-12 mt-3">
                        <span class="form-label-texts"> Durante as últimas 4 semanas, de que maneira sua saúde física ou
                            problemas emocionais interferiam nas suas atividades sociais (festas, casa de amigos,
                            aniversário) normais, em relação à família, vizinhos, amigos ou em grupo? </span>

                        <!-- InterferenciaSocial -->
                        <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesGravidade">
                            <input formControlName="InterferenciaSocial" class="form-input-checkbox" type="radio"
                                [value]="opcao.value"
                                [checked]="formGroupEtapa8.get('InterferenciaSocial').value === opcao.value" />
                            <label class="form-label-texts-helper-check">
                                {{ opcao.label }}
                            </label>
                        </div>
                        <!-- InterferenciaSocial -->

                    </div>

                    <!-- DorCorpo -->
                    <div class="col-md-6 mt-3">
                        <span class="form-label-texts"> Quanta dor no corpo você teve durante as últimas 4 semanas?
                        </span>

                        <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesGravidade">
                            <input formControlName="DorCorpo" class="form-input-checkbox" type="radio"
                                [value]="opcao.value"
                                [checked]="formGroupEtapa8.get('DorCorpo').value === opcao.value" />
                            <label class="form-label-texts-helper-check">
                                {{ opcao.label }}
                            </label>
                        </div>
                    </div>
                    <!-- DorCorpo -->

                    <!-- InterferenciaDorCorpo -->
                    <div class="col-md-6 mt-3">
                        <span class="form-label-texts"> Durante as últimas 4 semanas, quanto a dor interferiu com o seu
                            trabalho normal (incluindo tanto o trabalho, fora de casa e dentro de casa)? </span>

                        <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesGravidade">
                            <input formControlName="InterferenciaDorCorpo" class="form-input-checkbox" type="radio"
                                [value]="opcao.value"
                                [checked]="formGroupEtapa8.get('InterferenciaDorCorpo').value === opcao.value" />
                            <label class="form-label-texts-helper-check">
                                {{ opcao.label }}
                            </label>
                        </div>
                    </div>
                    <!-- InterferenciaDorCorpo -->

                    <div class="col-12 mt-3">
                        <span class="form-label-texts-helper">As perguntas abaixo são para saber como você se sente e
                            como tudo tem acontecido com você nas últimas quatro semanas. Para cada questão, por favor,
                            dê uma resposta que mais se aproxime da maneira com você se sente. Em relação às últimas
                            quatro semanas. </span>
                    </div>

                    <!-- Vigor -->
                    <div class="col-md-6 mt-3">
                        <span class="form-label-texts">Cheio de vigor, cheio de vontade, cheio de força? </span>

                        <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesTempo">
                            <input formControlName="Vigor" class="form-input-checkbox" type="radio"
                                [value]="opcao.value" [checked]="formGroupEtapa8.get('Vigor').value === opcao.value" />
                            <label class="form-label-texts-helper-check">
                                {{ opcao.label }}
                            </label>
                        </div>

                    </div>
                    <!-- Vigor -->

                    <!-- TempoNervoso -->
                    <div class="col-md-6 mt-3">
                        <span class="form-label-texts"> Quanto tempo você tem se sentido uma pessoa muito nervosa?
                        </span>

                        <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesTempo">
                            <input formControlName="TempoNervoso" class="form-input-checkbox" type="radio"
                                [value]="opcao.value"
                                [checked]="formGroupEtapa8.get('TempoNervoso').value === opcao.value" />
                            <label class="form-label-texts-helper-check">
                                {{ opcao.label }}
                            </label>
                        </div>
                    </div>
                    <!-- TempoNervoso -->

                    <!-- TempoDeprimido -->
                    <div class="col-md-6 mt-3">
                        <span class="form-label-texts"> Quanto tempo você tem se sentido tão deprimido que nada pode te
                            animar? </span>
                        <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesTempo">
                            <input formControlName="TempoDeprimido" class="form-input-checkbox" type="radio"
                                [value]="opcao.value"
                                [checked]="formGroupEtapa8.get('TempoDeprimido').value === opcao.value" />
                            <label class="form-label-texts-helper-check">
                                {{ opcao.label }}
                            </label>
                        </div>
                    </div>
                    <!-- TempoDeprimido -->

                    <!-- TempoCalmo -->
                    <div class="col-md-6 mt-3">
                        <span class="form-label-texts"> Por quanto tempo você tem se sentido calmo ou tranquilo? </span>
                        <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesTempo">
                            <input formControlName="TempoCalmo" class="form-input-checkbox" type="radio"
                                [value]="opcao.value"
                                [checked]="formGroupEtapa8.get('TempoCalmo').value === opcao.value" />
                            <label class="form-label-texts-helper-check">
                                {{ opcao.label }}
                            </label>
                        </div>
                    </div>
                    <!-- TempoCalmo -->

                    <!-- TempoEnergia -->
                    <div class="col-md-6 mt-3">
                        <span class="form-label-texts"> Quanto tempo você tem se sentido com muita energia? </span>

                        <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesTempo">
                            <input formControlName="TempoEnergia" class="form-input-checkbox" type="radio"
                                [value]="opcao.value"
                                [checked]="formGroupEtapa8.get('TempoEnergia').value === opcao.value" />
                            <label class="form-label-texts-helper-check">
                                {{ opcao.label }}
                            </label>
                        </div>
                    </div>
                    <!-- TempoEnergia -->

                    <!-- TempoAbatido -->
                    <div class="col-md-6 mt-3">
                        <span class="form-label-texts"> Quanto tempo você tem se sentido desanimado e abatido? </span>

                        <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesTempo">
                            <input formControlName="TempoAbatido" class="form-input-checkbox" type="radio"
                                [value]="opcao.value"
                                [checked]="formGroupEtapa8.get('TempoAbatido').value === opcao.value" />
                            <label class="form-label-texts-helper-check">
                                {{ opcao.label }}
                            </label>
                        </div>
                    </div>
                    <!-- TempoAbatido -->

                    <!-- TempoEsgotado -->
                    <div class="col-md-6 mt-3">
                        <span class="form-label-texts"> Quanto tempo você tem se sentido esgotado? </span>

                        <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesTempo">
                            <input formControlName="TempoEsgotado" class="form-input-checkbox" type="radio"
                                [value]="opcao.value"
                                [checked]="formGroupEtapa8.get('TempoEsgotado').value === opcao.value" />
                            <label class="form-label-texts-helper-check">
                                {{ opcao.label }}
                            </label>
                        </div>
                    </div>
                    <!-- TempoEsgotado -->

                    <!-- TempoFeliz -->
                    <div class="col-md-6 mt-3">
                        <span class="form-label-texts"> Quanto tempo você tem se sentido uma pessoa feliz? </span>

                        <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesTempo">
                            <input formControlName="TempoFeliz" class="form-input-checkbox" type="radio"
                                [value]="opcao.value"
                                [checked]="formGroupEtapa8.get('TempoFeliz').value === opcao.value" />
                            <label class="form-label-texts-helper-check">
                                {{ opcao.label }}
                            </label>
                        </div>
                    </div>
                    <!-- TempoFeliz -->

                    <!-- TempoCansado -->
                    <div class="col-md-6 mt-3">
                        <span class="form-label-texts"> Quanto tempo você tem se sentido cansado? </span>

                        <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesTempo">
                            <input formControlName="TempoCansado" class="form-input-checkbox" type="radio"
                                [value]="opcao.value"
                                [checked]="formGroupEtapa8.get('TempoCansado').value === opcao.value" />
                            <label class="form-label-texts-helper-check">
                                {{ opcao.label }}
                            </label>
                        </div>
                    </div>
                    <!-- TempoCansado -->

                    <div class="col-12 mt-3">
                        <span class="form-label-texts">Durante as últimas 4 semanas, quanto do seu tempo a sua saúde
                            física ou problemas emocionais interferiam com as suas atividades sociais (como visitar
                            amigos, parentes, festas etc)? </span>
                    </div>

                    <!-- TempoSaudeInterferiu -->
                    <div class="col-12 mt-3">

                        <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesTempo">
                            <input formControlName="TempoSaudeInterferiu" class="form-input-checkbox" type="radio"
                                [value]="opcao.value"
                                [checked]="formGroupEtapa8.get('TempoSaudeInterferiu').value === opcao.value" />
                            <label class="form-label-texts-helper-check">
                                {{ opcao.label }}
                            </label>
                        </div>
                    </div>
                    <!-- TempoSaudeInterferiu -->

                    <div class="col-12 mt-3">
                        <span class="form-label-texts-helper"> O quanto verdadeiro ou falso é cada uma das afirmações
                            para você? </span>
                    </div>

                    <!-- AdoecerComparado -->
                    <div class="col-md-6 mt-3">
                        <span class="form-label-texts"> Eu costumo adoecer um pouco mais facilmente que as outras
                            pessoas </span>

                        <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesVeracidade">
                            <input formControlName="AdoecerComparado" class="form-input-checkbox" type="radio"
                                [value]="opcao.value"
                                [checked]="formGroupEtapa8.get('AdoecerComparado').value === opcao.value" />
                            <label class="form-label-texts-helper-check">
                                {{ opcao.label }}
                            </label>
                        </div>
                    </div>
                    <!-- AdoecerComparado -->

                    <!-- Saudavel -->
                    <div class="col-md-6 mt-3">
                        <span class="form-label-texts"> Eu sou tão saudável quanto qualquer pessoa que eu conheço
                        </span>
                        <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesVeracidade">
                            <input formControlName="Saudavel" class="form-input-checkbox" type="radio"
                                [value]="opcao.value"
                                [checked]="formGroupEtapa8.get('Saudavel').value === opcao.value" />
                            <label class="form-label-texts-helper-check">
                                {{ opcao.label }}
                            </label>
                        </div>
                    </div>
                    <!-- Saudavel -->

                    <!-- SaudePiorar -->
                    <div class="col-md-6 mt-3">
                        <span class="form-label-texts"> Eu acho que a minha saúde vai piorar </span>
                        <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesVeracidade">
                            <input formControlName="SaudePiorar" class="form-input-checkbox" type="radio"
                                [value]="opcao.value"
                                [checked]="formGroupEtapa8.get('SaudePiorar').value === opcao.value" />
                            <label class="form-label-texts-helper-check">
                                {{ opcao.label }}
                            </label>
                        </div>
                    </div>
                    <!-- SaudePiorar -->

                    <!-- SaudeExcelente -->
                    <div class="col-md-6 mt-3">
                        <span class="form-label-texts"> Minha saúde é excelente </span>
                        <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesVeracidade">
                            <input formControlName="SaudeExcelente" class="form-input-checkbox" type="radio"
                                [value]="opcao.value"
                                [checked]="formGroupEtapa8.get('SaudeExcelente').value === opcao.value" />
                            <label class="form-label-texts-helper-check">
                                {{ opcao.label }}
                            </label>
                        </div>
                    </div>
                    <!-- SaudeExcelente -->

                  <div class="col-12 mb-2 mt-4" *ngIf="erroAvancarAnamnese">
                        <div class="alert alert-danger" role="alert">
                            {{ erroAvancarAnamnese }}
                        </div>
                    </div>

                    <div class="col-12 form-group-buttons row" style="margin-left: 20px;">
                        <div class="col-md-3 mb-3 ">
                            <button class="btn btn-secondary btn-block btn-lg form-bnt-voltar"
                                    (click)="handleClickFecharModal()">Fechar
                            </button>
                        </div>
                        <div class="col-md-3 mb-3 ">
                            <button class="btn btn-secondary btn-block btn-lg form-bnt-voltar"
                                    (click)="handleClickVoltarModal()">Voltar
                            </button>
                        </div>

                        <div class="col-md-6 mb-3" *ngIf="!carregandoAtualizarAnamnese">
                            <button class="btn btn-primary btn-block btn-sm form-bnt-salvar"
                                    (click)="handleClickProximoModal()">Próximo
                            </button>
                        </div>

                        <div class="col-md-6 mb-3" *ngIf="carregandoAtualizarAnamnese">
                            <button class="btn btn-primary btn-block btn-sm form-bnt-salvar"
                                    (click)="handleClickProximoModal()" disabled>Carregando
                            </button>
                        </div>
                    </div>
                 
                </div>
            </form>
        </div>
    </div>
</div>
