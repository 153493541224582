import {getHeaders} from '@/utils/getHeaders';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from 'environments/environment';
import {BehaviorSubject, Observable} from 'rxjs';

interface CheckupDadosTeia {
    Dor: null
    Felicidade: null
    Estresse: null
    CapacidadeFuncional: null
    Percepcao: null
    Disposicao: null
    Sono: null
    Animo: null
    Gratidao: null
    Fadiga: null
    Ansiedade: null
}

interface Checkup {
    IdentificadorHash: string
    ID: number
    SituacaoCheckup: number
    DataCheckup: Date
    DataAnamnese: Date
    DataAvaliacao: Date
    Avaliador: string
    Z: number,
    HashAnamnese: string
    HashAvaliacao: string
    DadosTeia: CheckupDadosTeia | null | undefined
}

@Injectable({
    providedIn: 'root'
})
export class CheckupClienteService {
    private api = environment.endpoint_api
    private _checkup: BehaviorSubject<Checkup | any> = new BehaviorSubject<Checkup | any>({})

    checkup$: Observable<Checkup | any> = this._checkup.asObservable()

    constructor(private http: HttpClient,) {
    }


    getCheckupCliente(idUsuario: number | string) {
        const headers = getHeaders()
        const classeContext = this

        this.http.get(this.api + "/Usuario/ObterCheckupsCliente/" + idUsuario, {headers}).subscribe({
            next(response) {
                // @ts-ignore
                const arrayResponse = Array.from(response)
                if (arrayResponse.length) {
                    {
                        // @ts-ignore
                        // const checkupNaoPreenchido = arrayResponse.find(checkup => checkup?.DataAnamnese === null) as Checkup | undefined
                        const checkupNaoPreenchido = classeContext.ObterHashAnamneseAberta(arrayResponse)
                        const ultimoCheckupPreenchido = classeContext.ObterHashUltimoCheckUp(arrayResponse)

                        if (checkupNaoPreenchido) {
                            classeContext._checkup.next(checkupNaoPreenchido)
                        } else if (ultimoCheckupPreenchido) {
                            classeContext._checkup.next(ultimoCheckupPreenchido)
                        }
                    }
                }
            }, error(error) {
            }
        })
    }


    ObterHashAnamneseAberta(checkups) {
        const anamnesesAbertas = checkups.filter(c => !c.DataAnamnese || c.DataAnamnese.toString() === "0001-01-01T00:00:00");
        if (anamnesesAbertas.length) {
            const checkup = anamnesesAbertas[anamnesesAbertas.length - 1];
            return checkup;
        }

        return null;
    }

    ObterHashUltimoCheckUp(checkups) {
        const finalizados = checkups.filter(c => !!c.DataAnamnese
            // && !!c.DataAvaliacao
            && c.SituacaoCheckup === 1);
        if (finalizados.length) {
            const checkup = finalizados[finalizados.length - 1];
            return checkup;
        }
        return null;
    }
}
