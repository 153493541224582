import {Component, OnInit} from '@angular/core';
import {AppService} from "@services/app.service";
import {CheckupClienteService} from "@services/dashboard/checkup-cliente/checkup-cliente.service";
import {AnamneseService} from "@services/dashboard/anamnese/anamnese.service";
import {Router} from "@angular/router";
import {ProfessorService} from "@services/dashboard/professor/professor.service";
import { Store } from '@ngrx/store';
import { AppState } from '@/store/state';
import { ToggleSidebarMenu } from '@/store/ui/actions';
import { ToastrService } from 'ngx-toastr';
import { ModalReenviarSenhaComponent } from '@components/dashboard/modais/Modal-ReenviarSenha/modal-reenviarSenha.component';
import { ModalSoliciarCheckupComponent } from '@components/dashboard/modais/Modal-SoliciarCheckup/modal-SoliciarCheckup.component';

@Component({
  selector: 'app-dashboard-avaliador',
  templateUrl: './dashboard-avaliador.component.html',
  styleUrls: ['./dashboard-avaliador.component.scss']
})
export class DashboardAvaliadorComponent implements OnInit {
  public usuarioLogado = null
  public listaPacientes: any = []
  public loadingListaPacientes = false;
  public loadingCupom = false;
  public meuLink: string = ""
  public filtroBuscaInput: string = ""
  public buscaFiltrada: any = []
  public laudoSemDados = false
  public urlSafe: string = ""
  public cupom: any = {}

  copiarMeuLink() {
    /* Get the text field */
    var copyText = document.getElementById("meu-link-avaliador-input");

    /* Select the text field */
    // @ts-ignore
    copyText.select();
    // @ts-ignore
    copyText.setSelectionRange(0, 99999); /* For mobile devices */

    /* Copy the text inside the text field */
    document.execCommand("copy");
  }

    constructor(public appService: AppService,
                private checkupClienteService: CheckupClienteService,
                public anamneseService: AnamneseService,
                private router: Router,
                private professorService: ProfessorService, // Injete o serviço
                private store: Store<AppState>,
                private toastr: ToastrService,
    ) {
    }

  ngOnInit(): void {
    this.usuarioLogado = this.appService.getDadosUsuarioLogadoStorage()
    if (this.usuarioLogado?.ID) {
      this.checkupClienteService.getCheckupCliente(this.usuarioLogado.ID)
      this.gerarLinkAvaliador()
    }

    this.loadingListaPacientes = true
    this.professorService.getListaPacientesProfessor(this.usuarioLogado.ID).subscribe((response: any) => {
      this.listaPacientes = response
       this.buscaFiltrada = response
    }, (error) => {
    }, () => {
      this.loadingListaPacientes = false
    })

    this.loadingCupom = true
    this.professorService.getCupomProfessor().subscribe((response: any) => {
      this.cupom = response
     }, (error) => {
    }, () => {
      this.loadingCupom = false
    })

      // @ts-ignore
  if (window.innerWidth <  926 && this.store?.source?._value?.ui?.menuSidebarCollapsed == false) {
    this.store.dispatch(new ToggleSidebarMenu());
  }
  }

  // gerar meu link avaliador
  gerarLinkAvaliador() {
    const nome = this.ajustarNome(this.appService.user?.Nome);
    const base64 = btoa("" + this.appService?.user?.ID);
    this.urlSafe =  window.location.origin;
      this.meuLink = `${this.urlSafe}/cadastro/${nome}#!/${base64}`;
  }

  handleClickAbrirModalReenviarSenha() {
    this.appService.openModal(ModalReenviarSenhaComponent)
  }

  handleClickAbrirModalSoliciarCheckup() {
    this.appService.openModal(ModalSoliciarCheckupComponent)
  }

  buscarPacientesPorFiltro() {
    if (this.filtroBuscaInput.length <= 0) {
      this.buscaFiltrada = this.listaPacientes
      return
    }

    this.buscaFiltrada = this.listaPacientes.filter(paciente => paciente?.Cliente?.toLowerCase().includes(this.filtroBuscaInput.toLowerCase()))
  }

  copiarCupom( cupom: string) {
     
    //copia o cupom para a area de transferencia 
    const el = document.createElement('textarea');
    el.value = cupom;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    this.toastr.success("Sucesso", "Seu cupom foi copiado!")


  }
  copiarLink(cod: string) {
    //copia o link para a area de transferencia 
    const el = document.createElement('textarea');
    el.value =  `${this.urlSafe}/cupom/promocao/${cod}`;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el)
    this.toastr.success("Sucesso", "Seu link foi copiado!")
  }

  toggleCheckboxBaixarLaudo(){
    this.laudoSemDados = !this.laudoSemDados
  }
 
  baixarLaudo(id: number) {
    if (!id) return
    if (this.laudoSemDados) {
      window.open(`https://api.plataformasafe.com.br/home/DownloadLaudoSemDadosPessoais/${id}`, "_blank")
      return
    }
    window.open(`https://api.plataformasafe.com.br/home/DownloadLaudoCompleto/${id}`, "_blank")
  }

  ajustarNome(nome: string | any) {
    if (!nome) return nome
    const partes = nome.split(" ");
    return partes[0] + "-" + partes[partes.length - 1];
  }


}
